import React,{useEffect, useState} from "react";
import { loop } from "../../Action/savedLoop.actions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const SavedLoops = (props) => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    
    const handleLoadMore = () => {
        setPage((prev) => prev + 1);
    };

    useEffect(() => {

        if (page > 0) {
            const filterData = {
                page: page + 1,
            };

            dispatch(
                loop.getSavedLoopDataAllUser(filterData, { Token: props?.token || props?.SocialToken })
            );
        }
    }, [page]);

    return (
        <div className="notes-list">
            {props?.SavedLoopLoadMoreDataAllUser?.length > 0 ? props?.SavedLoopLoadMoreDataAllUser?.map((savedLoop) => {
                return (
                    <div className="note-card">
                    <div className="loop-icon">
                        <img
                            src="/assets/img/learning/refresh-2.svg"
                            alt="Loop Icon"
                        />
                    </div>
                    <div className="note-content">
                    {savedLoop?.lesson?.path?.length > 0 ? (
                                <div className="breadcrumb-container">
                                    {[...savedLoop?.lesson?.path].reverse().map((notePath, index) => {
                                        const reversedIndex = savedLoop?.lesson?.path.length - 1 - index; 
                                        return (
                                            <div className="breadcrumb" key={reversedIndex}>
                                                {reversedIndex === savedLoop?.lesson?.path.length - 1 ? (
                                                    <>
                                                        <span className="breadcrumb-item">{notePath.title}</span>
                                                        {savedLoop?.lesson?.path.length > 1 && <span className="breadcrumb-separator">›</span>}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="breadcrumb-item">{notePath.title}</span>
                                                        {reversedIndex !== 0 && <span className="breadcrumb-separator">›</span>}
                                                    </>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                ''
                            )}
                        <p>
                           {savedLoop?.title}
                        </p>
                    </div>
                    <div className="note-time loop-time">{`${savedLoop?.start} - ${savedLoop?.end}`}</div> 
                    <div class="action-dropdown">
                        <div class="dropdown">
                            <button
                                class="btn dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <i class="bi bi-three-dots-vertical"></i>
                            </button>
                            <ul
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                            >
                                <li>
                                    <Link
                                        class="dropdown-item edit"
                                        to={`/${savedLoop?.lesson?.instrument.toLowerCase()}-lessons/${savedLoop?.lesson?.hash}-${savedLoop?.lesson?.id}?loop`}
                                    >
                                        <img
                                            src="assets/img/learning/edit.png"
                                            alt=""
                                        />
                                        Edit Loop
                                    </Link>
                                </li>
                                <li>
                                    <a
                                        class="dropdown-item delete"
                                        href="javascript:void(0)"
                                        onClick={() =>
                                            props?.handleDelete(
                                                false,savedLoop?.id
                                            )
                                        }
                                        id='paypal-button' data-bs-toggle="modal" data-bs-target="#delete-modal"
                                    >
                                        <img
                                            src="assets/img/learning/trash.png"
                                            alt=""
                                        />
                                        Delete Loop
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                )
            }):(
                <p style={{ textAlign: 'center' }}>No record found</p>
            )}
            <div class="common-load-more-btn">
                            {!props?.loopLoading &&
                                props?.SavedLoopLoadMoreDataAllUser?.length >
                                0 &&
                                props?.SavedLoopLoadMoreDataAllUser?.length <
                                props?.savedLoopAllUser?.total ? (
                                <a
                                    href="javascript:void(0);"
                                    onClick={handleLoadMore}
                                    style={{ color: "black" }}
                                >
                                    View More
                                </a>
                            ) : props?.loopLoading &&
                            props?.SavedLoopLoadMoreDataAllUser
                                    ?.length ? (
                                "Loading..."
                            ) : (
                                ""
                            )}
                        </div>
           
        </div>
    );
};

export default SavedLoops;
