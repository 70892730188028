import { auth } from "../Action/auth.actions";
import { authConstants } from "../Constants/Auth";

const initialState = {
    success: {},
    error: {},
    auth: false,
    submitted: false,
    regSubmitted: false,
    resetPassFlag: false,
    forgotPasswordFlag: false,
    userDetails: {},
    contactFlag: false,
    changePassFlag: false,
    editProfileFlag: false,
    signUpFlag: false,
    userProfile: [],
    newsLetterFlag: false,
    subscriptionSuccess: false,
    countryCode: [],
    requestLesson: false,
    submitLesson: false,
    newsletterLoading: false,
    emailSubmitted: false,
    sendCode: false,
    requestCode: false,
    socialSubmit: false,
    successProfileFlag: false,
    avtarSubmit: false,
    uploadImage: false,
    notification: {},
    streak: {},
    notificationButton: false,
    socialLoading: false,
    socialValue: false,
    studentDetails: {},
    studentSubmit: false,
    studentData: {},
    whatsAppotpSending: false,
    whatsAppotpVerifying: false,
    whatsAppOtpSentResponse: {},
    whatsAppOtpVerifiedResponse: {},
}
let userDetails = {};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case authConstants.SIGNIN_SUCCESS:
            return {
                ...state,
                auth: true,
                userDetails: action.payload,
                submitted: false,
                error: {},
            }
            break;

        case authConstants.SIGNIN_REQUEST:
            return {
                ...state,
                submitted: true,
                userDetails: state.userDetails
            }
            break;

        case authConstants.SIGNIN_FAILURE:
            return {
                ...state,
                submitted: false,
                userDetails: action.payload,
                error: { "type": "signin", "message": action.payload?.message }
            }
            break;

        case authConstants.SIGNUP_REQUEST:
            return {
                ...state,
                regSubmitted: true,
                signUpFlag: false
            };
            break;

        case authConstants.SIGNUP_SUCCESS:
            return {
                auth: false,
                userDetails: action.payload,
                userProfile: action.payload,
                regSubmitted: false,
                signUpFlag: true,
                error: {}
            };
            break;

        case authConstants.SIGNUP_FAILURE:
            return {
                ...state,
                signUpFlag: false,
                regSubmitted: false,
                error: { "type": "signup", "message": action?.payload?.message }
            };
            break;

        case authConstants.SIGNOUT_REQUEST:
            return {
                ...state,
                auth: false,
                submitted: true,
                userDetails: {}
            };
            break;

        case authConstants.SIGNOUT_SUCCESS:
            return {
                ...initialState,
                auth: false,
                submitted: false,
            };
            break;

        case authConstants.SIGNOUT_FAILURE:
            return {
                ...state,
                auth: false,
                submitted: false,
                userDetails: {}
            };
            break;

        case authConstants.FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                submitted: true,
                forgotPasswordFlag: false,
            };
            break;

        case authConstants.FORGOT_PASSWORD_SUCCESS:
            return {
                auth: false,
                userDetails: action.payload,
                forgotPasswordFlag: true,
                submitted: false,
                error: {}
            };
            break;

        case authConstants.FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                submitted: false,
                forgotPasswordFlag: false,
                error: { "type": "forgotPassword", "message": action.payload?.message }
            };
            break;

        case authConstants.UPDATE_FORGOT_PASS_FLAG:
            const stateObject = initialState;
            stateObject.forgotPasswordFlag = false;
            stateObject.userDetails.email = action.payload;

            return stateObject;
            break;

        case authConstants.UPDATE_REGISTER_FLAG:
            const stateObjt = initialState;
            stateObjt.signUpFlag = false;
            stateObjt.userDetails.email = action.payload;

            return stateObjt;
            break;

        case authConstants.RESET_FLAG:
            const stateObjctS = initialState;
            stateObjctS.resetPassFlag = false;
            stateObjctS.userDetails.data = action.payload;

            return stateObjctS;
            break;

        case authConstants.RESET_PASSWORD_REQUEST:
            return {
                ...state,
                resetSubmitted: true,
                resetPassFlag: false
            };
            break;

        case authConstants.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetSubmitted: false,
                resetPassFlag: true,
                sendCode: false,
                error: {}
            };
            break;

        case authConstants.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                resetSubmitted: false,
                resetPassFlag: false,
                error: { "type": "resetPassword", "message": action.payload?.message }
            };
            break;

        case authConstants.CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                submitted: true,
                changePassFlag: false,
            };
            break;

        case authConstants.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                submitted: false,
                changePassFlag: true,
                error: {}
            };
            break;

        case authConstants.CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                submitted: false,
                changePassFlag: false,
                error: { "type": "changepassword", "message": action.payload?.message }
            };
            break;

        case authConstants.EDIT_PROFILE_REQUEST:
            return {
                ...state,
                submitted: true,
                avtarSubmit: true,
                successProfileFlag: false,
            };
            break;

        case authConstants.EDIT_PROFILE_SUCCESS:
            return {
                ...state,
                submitted: false,
                editProfileFlag: true,
                avtarSubmit: false,
                userProfile: action.payload,
                successProfileFlag: true,
                error: {}
            };
            break;

        case authConstants.EDIT_PROFILE_FAILURE:
            return {
                ...state,
                submitted: false,
                editProfileFlag: false,
                avtarSubmit: false,
                successProfileFlag: false,
                error: { "type": "saveChanges", "message": action.payload?.message }
            };
            break;

        case authConstants.UPDATE_CONTACT_FLAG:
            const stateObj = initialState;
            stateObj.contactFlag = false;

            return stateObj;
            break;

        case authConstants.GET_EDIT_PROFILE_PENDING:
            return {
                ...state,
                loading: true,
            };
            break;

        case authConstants.GET_EDIT_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                changePassFlag: false,
                userProfile: action.payload,
            };
            break;

        case authConstants.GET_EDIT_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
            break;

        case authConstants.NEWSLETTER_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                newsletterLoading: true
            };
            break;

        case authConstants.NEWSLETTER_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                newsletterLoading: false,
                error: {}
            };
            break;

        case authConstants.NEWSLETTER_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                newsletterLoading: false,
                error: { "type": "newsLetterSubscription", "message": action.payload.message }
            };
            break;

        case authConstants.REQUEST_SONGLESSON_REQUEST:
            return {
                ...state,
                requestLesson: false,
                submitLesson: true
            };
            break;

        case authConstants.REQUEST_SONGLESSON_SUCCESS:
            return {
                auth: true,
                userDetails: action.payload,
                submitLesson: false,
                requestLesson: true,
                error: {}
            };
            break;

        case authConstants.REQUEST_SONGLESSON_FAILURE:
            return {
                ...state,
                requestLesson: false,
                submitLesson: false,
                error: { "type": "requestSongLesson", "message": action.payload.message }
            };
            break;

        case authConstants.RESET_FLAGS:
            return {
                ...state,
                resetPassFlag: false,
                forgotPasswordFlag: false,
                submitted: false,
                subscriptionSuccess: false,
                requestLesson: false,
                sendCode: false,
                requestCode: false,
                whatsAppotpVerifying:false,
                whatsAppotpSending:false,
                
            };
            break;

        case authConstants.SOCIAL_LOGIN_REQUEST:
            return {
                ...state,
                socialLoading: true,
                socialSubmit: true,
                userDetails: {}
            };
            break;

        case authConstants.SOCIAL_LOGIN_SUCCESS:
            return {
                auth: true,
                socialLoading: false,
                userDetails: action.payload,
                socialSubmit: false,
                error: {}
            };
            break;

        case authConstants.SOCIAL_LOGIN_FAILURE:
            return {
                ...state,
                socialSubmit: false,
                socialLoading: false,
                socialValue: false,
                error: { "type": "forgotPassword", "message": action.payload?.message }
            };
            break;

        case authConstants.GET_COUNTRYCODE_PENDING:
            return {
                ...state,
                loading: true,
            };
            break;

        case authConstants.GET_COUNTRYCODE_SUCCESS:
            return {
                ...state,
                loading: false,
                countryCode: action.payload,
            };
            break;

        case authConstants.GET_COUNTRYCODE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
            break;

        case authConstants.UPDATE_NEWSLETTER_FLAG:
            const stateObjet = initialState;
            stateObjet.newsLetterFlag = false;
            stateObjet.userDetails.email = action.payload;

            return stateObjet;
            break;

        case authConstants.VERIFY_TOKEN_SUCCESS:
            debugger
            return {
                auth: true,
                userDetails: action.payload,
                submitted: false,
                error: {},
            }
            break;

        case authConstants.VERIFY_TOKEN_REQUEST:
            return {
                ...state,
                submitted: true,
            }
            break;

        case authConstants.VERIFY_TOKEN_FAILURE:
            return {
                ...state,
                submitted: false,
                error: { "type": "verifyToken", "message": action.payload?.message }
            }
            break;

        case authConstants.VERIFY_EMAIL_SUCCESS:
            return {
                // auth: true,
                userDetails: action.payload,
                emailSubmitted: false,
                error: {},
            }
            break;

        case authConstants.VERIFY_EMAIL_REQUEST:
            return {
                ...state,
                emailSubmitted: true,
            }
            break;

        case authConstants.VERIFY_EMAIL_FAILURE:
            return {
                ...state,
                emailSubmitted: false,
                error: { "type": "verifyToken", "message": action.payload?.message }
            }
            break;

        case 'SET_LESSONS_LISTING':
            return {
                lessonListing: action.payload
            }

        case 'SOCIAL_LOADING':
            return {
                socialValue: action.payload
            }

        case authConstants.RESEND_PENDING:
            return {
                ...state,
                requestCode: false,
                sendCode: true
            };
            break;

        case authConstants.RESEND_SUCCESS:
            return {
                auth: false,
                userDetails: action.payload,
                sendCode: true,
                requestCode: true,
                error: {}
            };
            break;

        case authConstants.RESEND_FAILURE:
            return {
                ...state,
                sendCode: false,
                requestCode: false,
                error: { "type": "resendCode", "message": action.payload?.message }
            };
            break;

        case authConstants.STREAK_PENDING:
            return {
                ...state,
            };
            break;

        case authConstants.STREAK_SUCCESS:
            return {
                ...state,
                streak: action.payload,
            };
            break;

        case authConstants.STREAK_FAILURE:
            return {
                ...state,
                error: { "type": "Streak", "message": action?.payload?.message },
                streak: {}
            };
            break;

        case authConstants.NOTIFICATION_PENDING:

            return {
                ...state,
            };
            break;

        case authConstants.NOTIFICATION_SUCCESS:
            return {
                ...state,
                notification: action.payload,
            };
            break;

        case authConstants.NOTIFICATION_FAILURE:
            return {
                ...state,
            }
            break;

        case 'RESET_FORM':
            return {
                userDetails: {}
            }
            break;

        case authConstants.STUDENT_LOGIN_PENDING:
            return {
                ...state,
                studentData: {},
                studentSubmit: true,

            };
            break;

        case authConstants.STUDENT_LOGIN_SUCCESS:
            return {
                ...state,
                studentData: action.payload,
                studentSubmit: false

            };
            break;

        case authConstants.STUDENT_LOGIN_FAILURE:
            return {
                ...state,
                studentSubmit: false,
                studentData: action.payload

            };
            break;

        case authConstants.STUDENT_VERIFICATION_PENDING:
            return {
                ...state,
                studentData: {},

            };
            break;

        case authConstants.STUDENT_VERIFICATION_SUCCESS:
            return {
                ...state,
                studentData: action.payload,

            };
            break;

        case authConstants.STUDENT_VERIFICATION_FAILURE:
            return {
                ...state,
                studentData: {}

            };
            break;

        case 'EMPTY_STUDENTDATA':
            return {
                ...state,
                studentData: {}

            };
            break;

        case 'EMPTY_AUTH_STATE':
            return {
                ...state,
                userDetails: {}
            }

            break;

        case authConstants.SEND_WHATSAPP_OTP_FAILURE:
            return {
                ...state,
                whatsAppOtpSentResponse: {},
                whatsAppotpSending: false,


            };
            break;

        case authConstants.SEND_WHATSAPP_OTP_SUCCESS:
            return {
                ...state,
                whatsAppOtpSentResponse: action.payload,
                whatsAppotpSending: false,
            };
            break;


        case authConstants.SEND_WHATSAPP_OTP_PENDING:
            return {
                ...state,
                whatsAppotpSending: true,
                whatsAppOtpSentResponse: {},


            };
            break;


        case authConstants.VERIFY_WHATSAPP_OTP_FAILURE:
            return {
                ...state,
                whatsAppOtpVerifiedResponse: {},
                whatsAppotpVerifying: false,
                auth: false


            };
            break;

        case authConstants.VERIFY_WHATSAPP_OTP_SUCCESS:
            return {
                ...state,
                whatsAppOtpVerifiedResponse: action.payload,
                whatsAppotpVerifying: false,
                userDetails: action.payload,
                auth: true

            };

            break;

        case authConstants.VERIFY_WHATSAPP_OTP_PENDING:
            return {
                ...state,
                whatsAppOtpVerifiedResponse: {},
                whatsAppotpVerifying: true,
                auth: false

            };
            break;

            case 'PROFILE_EMAIL_VERIFY_SUCESS':
                return {
                    auth: true,
                    userDetails: action.payload,
                    emailSubmitted: false,
                    error: {},
                }
                break;
    

        default:
            return state;
            break;
    }
}