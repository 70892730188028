import * as yup from "yup";

const isEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
let namevalidate = /^[^\d]*$/

export const RegisterSchema = yup.object().shape({
  name: yup.string().required("Please enter the name").matches(namevalidate, 'Name should be characters only.').min(3,"Minimum 3 character required").max(25,"Maximum 20 character allowed"),
  email: yup.string().required("Please enter the email").matches(isEmail, 'Please enter a valid email address'),
  password: yup.string().required("Minimum 8 characters required").min(8, "Minimum 8 characters required").max(20)
});


export const CreateAccountSchema = yup.object().shape({
  name: yup.string().required("Please enter the name").matches(namevalidate, 'Name should be characters only.').min(3,"Minimum 3 character required").max(25,"Maximum 20 character allowed"),
  email: yup.string().required("Please enter the email").matches(isEmail, 'Please enter a valid email address'),
  password: yup.string().required("Minimum 8 characters required").min(8, "Minimum 8 characters required").max(20),
  // dob: yup.string().required("Please enter the DOB")

});
