export const loopConstants = {
    GET_LOOP_PENDING: "GET_LOOP_PENDING",
    GET_LOOP_SUCCESS: "GET_LOOP_SUCCESS",
    GET_LOOP_FAILURE: "GET_LOOP_FAILURE",

    SAVED_LOOP_PENDING: "SAVED_LOOP_PENDING",
    SAVED_LOOP_SUCCESS: "SAVED_LOOP_SUCCESS",
    SAVED_LOOP_FAILURE: "SAVED_LOOP_FAILURE",

    DELETE_SAVED_LOOP_PENDING: "DELETE_SAVED_LOOP_PENDING",
    DELETE_SAVED_LOOP_SUCCESS: "DELETE_SAVED_LOOP_SUCCESS",
    DELETE_SAVED_LOOP_FAILURE: "DELETE_SAVED_LOOP_FAILURE",

    GET_SAVED_LOOP_PENDING: "GET_SAVED_LOOP_PENDING",
    GET_SAVED_LOOP_SUCCESS: "GET_SAVED_LOOP_SUCCESS",
    GET_SAVED_LOOP_FAILURE: "GET_SAVED_LOOP_FAILURE"

}
