import React,{useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { notes } from "../../Action/note.actions";
import { useDispatch } from "react-redux";

const SavedNote = (props) => {
    const dispatch = useDispatch()
    const [page, setPage] = useState(0);

   

    const handleLoadMore = () => {
        setPage((prev) => prev + 1);
    };

    useEffect(() => {

        if (page > 0) {
            const filterData = {
                page: page + 1,
            };

            dispatch(
                notes.getSavedNotes(filterData, { Token: props?.token || props?.SocialToken })
            );
        }
    }, [page]);


    return (
        <div className="notes-list">
            {props?.savedNoteData?.length > 0
                ? props?.savedNoteData?.map((savedNote) => {
                      return (
                          <div className="note-card">
                         
                          <Link to={`/${savedNote?.lesson?.instrument.toLowerCase()}-lessons/${savedNote?.lesson?.hash}-${savedNote?.lesson?.id}?notes`}>
                              <div className="note-icon">
                                  <img
                                      src="/assets/img/learning/note-icon.svg"
                                      alt="Note Icon"
                                  />
                              </div>
                            </Link>
                            <Link to={`/${savedNote?.lesson?.instrument.toLowerCase()}-lessons/${savedNote?.lesson?.hash}-${savedNote?.lesson?.id}?notes`}>
                              <div className="note-content">
                              {savedNote?.lesson?.path?.length > 0 ? (
                                <div className="breadcrumb-container">
                                    {[...savedNote?.lesson?.path].reverse().map((notePath, index) => {
                                        const reversedIndex = savedNote?.lesson?.path.length - 1 - index; 
                                        return (
                                            <div className="breadcrumb" key={reversedIndex}>
                                                {reversedIndex === savedNote?.lesson?.path.length - 1 ? (
                                                    <>
                                                        <span className="breadcrumb-item">{notePath.title}</span>
                                                        {savedNote?.lesson?.path.length > 1 && <span className="breadcrumb-separator">›</span>}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="breadcrumb-item">{notePath.title}</span>
                                                        {reversedIndex !== 0 && <span className="breadcrumb-separator">›</span>}
                                                    </>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                ''
                            )}

                                  <p>{savedNote?.text}</p>
                              </div>
                             </Link>
                              <div className="note-time">
                                  {savedNote?.start_time}
                              </div>
                              
                              <div class="action-dropdown">
                                  <div class="dropdown">
                                      <button
                                          class="btn dropdown-toggle"
                                          type="button"
                                          id="dropdownMenuButton1"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                      >
                                          <i class="bi bi-three-dots-vertical"></i>
                                      </button>
                                      <ul
                                          class="dropdown-menu"
                                          aria-labelledby="dropdownMenuButton1"
                                      >
                                          <li>
                                                <Link
                                                    class="dropdown-item edit"
                                                    to={`/${savedNote?.lesson?.instrument.toLowerCase()}-lessons/${savedNote?.lesson?.hash}-${savedNote?.lesson?.id}?notes`}
                                                >
                                                  <img
                                                      src="/assets/img/learning/edit.png"
                                                      alt=""
                                                  />
                                                  Edit Notes
                                              </Link>
                                          </li>
                                          <li>
                                              <a
                                                  class="dropdown-item delete"
                                                  href="javascript:void(0)"
                                                  onClick={() =>
                                                    props?.handleDelete(
                                                        true, savedNote?.id
                                                    )
                                                }
                                                 id='paypal-button' data-bs-toggle="modal" data-bs-target="#delete-modal"
                                              >
                                                  <img
                                                      src="/assets/img/learning/trash.png"
                                                      alt=""
                                                  />
                                                  Delete Notes
                                              </a>
                                          </li>
                                      </ul> 
                                  </div>
                              </div>
                           
                          </div>
                      );
                  })
                : (
                    <p style={{ textAlign: 'center' }}>No record found</p>
                )}
                <div class="common-load-more-btn">
                    {!props?.loading &&
                        props?.savedNoteData?.length >
                        0 &&
                        props?.savedNoteData?.length <
                        props?.savedNotes.total ? (
                        <a
                            href="javascript:void(0);"
                            onClick={handleLoadMore}
                            style={{ color: "black" }}
                        >
                            View More
                        </a>
                    ) : props?.loading &&
                    props?.savedNoteData
                            ?.length ? (
                        "Loading..."
                    ) : (
                        ""
                    )}
                </div>
        </div>
    );
};

export default SavedNote;
