import { loopConstants } from "../Constants/loopConstants";

const initialState = { 
    savedLoading: false,
    upload:{},
    SavedLoopLoadMoreData:[],
    deleteLoading:false,
    saveLoopSubmit:false,
    savedLoop:[],
    SavedLoopLoadMoreDataAllUser:[],
    savedLoopAllUser:[],
    loopLoading:false
}

export const savedLoopReducer = ( state=initialState, action ) => {
    switch(action.type) {
        case loopConstants.GET_LOOP_PENDING:
            return {
                ...state,
                savedLoading: true,
                savedLoop: [],
            };
            break;

        case loopConstants.GET_LOOP_SUCCESS:
            let updatedMoreData = [...state.SavedLoopLoadMoreData];
            updatedMoreData = (action.payload?.data) ? updatedMoreData.concat(action.payload?.data) : [];

            return {
                ...state,
                savedLoading: false,
                savedLoop: action.payload,
                SavedLoopLoadMoreData:updatedMoreData
               
            };
            break;

        case loopConstants.GET_LOOP_FAILURE:
            return {
                ...state,
                savedLoading: false,
            };
            break;

        case loopConstants.DELETE_SAVED_LOOP_PENDING: {
            return {
                ...state,
                deleteLoading:true,
            }
        }

        case loopConstants.DELETE_SAVED_LOOP_SUCCESS:
            const updatedSavedLoop = state.SavedLoopLoadMoreData.filter(
                (loop) => loop.id !== action.payload
            );
            const updatedSaved = state.SavedLoopLoadMoreDataAllUser.filter(
                (loop) => loop.id !== action.payload
            );

            return {
                ...state,
                SavedLoopLoadMoreData: updatedSavedLoop,
                SavedLoopLoadMoreDataAllUser:updatedSaved,
                savedLoop:action.payload,
                deleteLoading:false,
                savedLoopAllUser: action.payload
            };
            
        case loopConstants.DELETE_SAVED_LOOP_FAILURE: {
            return {
                ...state,
                deleteLoading:false,
            }
        }

        case 'SAVED_LOOP':
            return { 
                ...state,
                SavedLoopLoadMoreData: [],
                SavedLoopLoadMoreDataAllUser:[]
            };
            break; 

        case loopConstants.SAVED_LOOP_PENDING:
            return {
                ...state,
                saveLoopSubmit:true,
                savedLoading: true
            };
            break;

        case loopConstants.SAVED_LOOP_SUCCESS:
            return {
                ...state,
                saveLoopSubmit:false,
                savedLoading: false
            };
            break;

        case loopConstants.SAVED_LOOP_FAILURE:
            return {
                ...state,
                error: action.error,
                saveLoopSubmit:false,
                savedLoading: false
            };
            break;

        case loopConstants.GET_SAVED_LOOP_PENDING:
            return {
                ...state,
                loopLoading: true,
                savedLoopAllUser: [],
            };
            break;

        case loopConstants.GET_SAVED_LOOP_SUCCESS:
            let updatedSavedMoreData = [...state.SavedLoopLoadMoreDataAllUser];
            updatedSavedMoreData = (action.payload?.data) ? updatedSavedMoreData.concat(action.payload?.data) : [];

            return {
                ...state,
                loopLoading: false,
                savedLoopAllUser: action.payload,
                SavedLoopLoadMoreDataAllUser:updatedSavedMoreData
               
            };
            break;

        case loopConstants.GET_SAVED_LOOP_FAILURE:
            return {
                ...state,
                loopLoading: false,
            };
            break;

        default:
            return state;
        }
}