import { seoService } from "../Adapters/seo.service";
import { seoConstants } from "../Constants/SeoConstants";

export const seoAction= {
    getSeo,
    
}

function getSeo(params, headers) {
   
        let url = new URL(`${process.env.REACT_APP_API_URL}/api/seo`);
        for (let key in params) {
            url.searchParams.append(key, params[key]);
        }
    
        return (dispatch) => {
            dispatch(request());
            seoService
            .getSeo(url.href, headers)
            .then((response) => {
                dispatch(success(response?.data?.data));
            })
            .catch((error) => {
                dispatch(errorsSeo(error));
            });
        };
      
        function errorsSeo(error) {
            return { type: seoConstants.GET_SEO_FAILURE, error: error };
        }
      
        function request() {
            return { type: seoConstants.GET_SEO_PENDING };
        }
      
        function success(data) {
            return { type: seoConstants.GET_SEO_SUCCESS, payload: data };
        }

   
}