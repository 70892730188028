import React from "react";
import { useEffect, useState } from 'react';
import { LoginSocialGoogle } from 'reactjs-social-login'
import { GoogleLoginButton } from 'react-social-login-buttons'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from "react-router-dom";

//UseStates
import { FormInput } from '../../../../Utility/FormInput';
import { RegisterSchema } from '../../../../ValidationSchema/RegisterSchema';
import { auth } from '../../../../Action/auth.actions';
import { authConstants } from '../../../../Constants/Auth';
import { authService } from "../../../../Adapters/auth.services";
import '../../../../components/Common/CSS/common.css'
import '../../Courses.css'
import VerifyEmailModal from "./VerifyEmailModal";
import useWindowDimensions from "../../../../Helpers/useWindowDimensions";
import { Modal } from "react-bootstrap";

const schema = RegisterSchema;

const CourseSignUp = (props) => {
    const dispatch = useDispatch();
    const pathname = useLocation()
    const navigate = useNavigate()
    const { windowWidth } = useWindowDimensions();

    const authState = useSelector((state) => state.auth)
    const { socialToken, token } = authState?.userDetails;
    const [showPassword, setShowPassword] = useState(false);
    const [showVerify, setShowVerify] = useState(false);

    useEffect(() => {
        // var modal = document.getElementById('signupmodal');
        // modal.addEventListener('hide.bs.modal', function () {
        //     handleCloseModal();
        // });

    }, []);

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
        mode: "onSubmit"
    });

    useEffect(() => {
        // if (props?.authView === 'signup') {
        //     document.getElementById('signup-action').click();
        // }

    }, [props?.authView]);

    const removeBackdrop = () => {
        const modalBackdrops = document.querySelectorAll(
            ".modal-backdrop.fade.show"
        )
        modalBackdrops.forEach(modalBackdrop => {
            modalBackdrop?.parentNode?.removeChild(modalBackdrop);
        });

        setTimeout(() => {
            document.body.classNameList?.remove("modal-open");
            window.$('body').css('padding-right', '0px');
            // window.$('body').css('overflow', 'auto');
        }, 300);

    }

    const handleCloseModal = () => {
        dispatch({ type: 'SET_AUTH_VALUE', payload: '' })
        props?.setShowSignIn(false);
        props?.setShowSignUp(false);
        reset()

        // removeBackdrop();
        props?.onHide();
    }

    useEffect(() => {
        if (authState.signUpFlag === true) {
            dispatch(auth.updateRegisterFlag(authState.userDetails.email));
            setShowVerify(true)

        }
    }, [authState.signUpFlag, props?.authView]);


    const onSubmit = (data) => {
        const requestData = {
            ...data,
            name: data.name.trim(),
            password: data.password.trim(),
        };

        dispatch({ type: authConstants.SIGNUP_REQUEST });
        dispatch(auth.signUp(requestData));

        // removeBackdrop();
        dispatch(auth.getEditProfile({ token: token || socialToken }));

    };

    //social login fucntion here
    const handleSocialButton = async (data) => {
        handleCloseModal()
        dispatch({ type: authConstants.SOCIAL_LOGIN_REQUEST });
        await authService
            .sendRequest('/api/social-login', data)
            .then(response => {
                dispatch({ type: authConstants.SOCIAL_LOGIN_SUCCESS, payload: response.data });
                if (response?.data?.new_user) {
                    navigate(`${pathname?.pathname}?new_conversion`);
                } else if (response?.data?.new_user === false) {
                    navigate(`${pathname?.pathname}`)
                }
            })
            .catch((error) => {
                return dispatch({ type: authConstants.SOCIAL_LOGIN_FAILURE, error: error });
            });
        // removeBackdrop();

    };

    const handleKeyUp = (e) => {
        const inputValue = e.target.value;
        localStorage.setItem('email', inputValue);
    };

    const handleResendEmail = (e) => {
        e.preventDefault();
        const requestData = {
            email: localStorage.getItem('email')
        }

        dispatch(auth.verifyEmail(requestData))
        // removeBackdrop();
        props?.handleSwitchForm(); reset()
    }


    const modalCLose=()=>{
        props?.handleCloseSignUp()
        props?.onHide()
    }
    return (
        <>
            <Modal
                show={props?.show}
                onHide={modalCLose}
                centered
                size="md"
                id="signupmodal"
                animation={false}
                className={`modal fade signup-modal signInModalcourse whatsapp-login-page`}
            >
                <Modal.Body>
           
                        <button
                            type="button"
                            className="close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={modalCLose}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M20 20L4 4M20 4L4 20"
                                    stroke="black"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </button>
                        <div className="modal-body">
                            <div className="signup-modal-area">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 p-0">
                                        {!showVerify ?
                                            <div className="form-area">
                                                <div className="title">
                                                    <h4>
                                                        Create a free account and be a
                                                        part of Torrins community
                                                    </h4>
                                                </div>
                                                <form onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="form-group">
                                                        <FormInput
                                                            onkeydown="return /[a-z]/i.test(event.key)"
                                                            id="floatingInputValue"
                                                            register={register('name')}
                                                            name="name"
                                                            type="text"
                                                            autoComplete="off"
                                                            identity="name"
                                                            className="form-control"
                                                            placeholder="Enter full name here"
                                                            error={errors?.name}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <FormInput
                                                            id="floatingInputValue"
                                                            register={register('email')}
                                                            error={errors?.email}
                                                            name="email"
                                                            type="text"
                                                            identity="email"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            onKeyUp={(e) => handleKeyUp(e)}
                                                            placeholder="Enter your email here"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <FormInput
                                                            id="floatingInputValue"
                                                            register={register('password')}
                                                            name="password"
                                                            type={showPassword ? 'text' : 'password'}
                                                            identity="password"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="Enter your password"
                                                            error={errors?.password}
                                                        />
                                                        <div class="input-group-addon" style={{ textAlign: 'end' }}>
                                                            <Link to='#' id="showhide" onClick={() => setShowPassword(!showPassword)}><i className={`fa-regular fa-eye${showPassword === true ? '' : '-slash'}`} aria-hidden="true"></i></Link>
                                                        </div>
                                                    </div>

                                                    <div className="tm">
                                                        <p>
                                                            By clicking on register I agree
                                                            to all <Link to="/t&c" target="_blank" style={{ textDecoration: 'underline' }}>T&C</Link>
                                                        </p>
                                                    </div>

                                                    <div className="submit-btn">
                                                        {authState.regSubmitted ? <button>Loading..</button> : <button>Get Started</button>}
                                                    </div>
                                                    <div className='login-contents mb-2'>

                                                        <div className="register-with-google">
                                                            {props?.authView === 'signup' &&
                                                                    <LoginSocialGoogle
                                                                        client_id='595434321762-t901vug4d7dq10a1ts2m20pv9p0r9rip.apps.googleusercontent.com'
                                                                        access_type='online'
                                                                        className='google-reg'
                                                                        onLoginStart={() => handleCloseModal()}
                                                                        onResolve={({ data }) => {
                                                                            handleSocialButton(data);
                                                                        }}
                                                                        onReject={(err) => {
                                                                            console.log(err);
                                                                        }}
                                                                    >
                                                                        {authState.socialSubmit ?
                                                                            <GoogleLoginButton className='google' text='Loading...' /> :
                                                                            <GoogleLoginButton className='google' text={windowWidth > 761  ? '' : 'Register with Google'} />
                                                                        }
                                                                    </LoginSocialGoogle>
                                                                }

                                                        </div>
                                                        <div class="register-with-email" >
                                                            <Link to={'#'} onClick={()=>{
                                                                props?.setShowWhatsAppRegisterModal(true)
                                                                handleCloseModal()
                                                                }} >
                                                                <img src='/assets/img/Login/what.svg' alt='' />
                                                                {windowWidth > 761  ? '' : 'Register with WhatsApp'}
                                                            </Link>
                                                        </div>

                                                    </div>
                                                    <div className="have-login-link">
                                                        <p>
                                                            Have an account?{" "}
                                                            <a href="javascript:void(0)" onClick={() => { props?.handleSwitchForm(); reset() }}>Log In</a>
                                                        </p>
                                                    </div>
                                                </form>
                                            </div> :
                                            <VerifyEmailModal
                                                setShowModule={props?.setShowModule}
                                                handleResendEmail={handleResendEmail}
                                            />
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                  
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CourseSignUp;
