export const chordsConstants = {
    GET_CHORDS_PENDING: "GET_CHORDS_PENDING",
    GET_CHORDS_SUCCESS: "GET_CHORDS_SUCCESS",
    GET_CHORDS_FAILURE: "GET_CHORDS_FAILURE",

    GET_TABS_CHORDS_PENDING:'GET_TABS_CHORDS_PENDING',
    GET_TABS_CHORDS_SUCCESS:'GET_TABS_CHORDS_SUCCESS',
    GET_TABS_CHORDS_FAILURE:'GET_TABS_CHORDS_FAILURE',

    GET_CHORDS_DETAILS_PENDING:"GET_CHORDS_DETAILS_PENDING",
    GET_CHORDS_DETAILS_SUCCESS:"GET_CHORDS_DETAILS_SUCCESS",
    GET_CHORDS_DETAILS_FAILURE:"GET_CHORDS_DETAILS_FAILURE",

    GET_SIMILAR_CHORDS_PENDING:"GET_SIMILAR_CHORDS_PENDING",
    GET_SIMILAR_CHORDS_SUCCESS:"GET_SIMILAR_CHORDS_SUCCESS",
    GET_SIMILAR_CHORDS_FAILURE:"GET_SIMILAR_CHORDS_FAILURE"

}