import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoginSocialGoogle } from "reactjs-social-login";
import { GoogleLoginButton } from "react-social-login-buttons";
import { Link } from "react-router-dom";

import { FormInput } from "../../../../Utility/FormInput";
import { loginSchema } from "../../../../ValidationSchema/LoginSchema";
import { auth } from "../../../../Action/auth.actions";
import { authConstants } from "../../../../Constants/Auth";
import Button from "../../../../Utility/Button";
import '../../../../components/Common/CSS/common.css'

import { authService } from "../../../../Adapters/auth.services";

import { Button as ReactBtn } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import CustomSuccessToast from "../../../../components/CustomToaster/CustomSuccessToaster";
import { toast } from "react-toastify";
import useWindowDimensions from "../../../../Helpers/useWindowDimensions";
import { CreateAccountSchema } from "../../../../ValidationSchema/RegisterSchema";


const schema = CreateAccountSchema;

const CreateAccountModal = ({ handleCloseWhatsappModal, showCreateAccountModal, handleCreateAccountModal }) => {

    const { windowWidth } = useWindowDimensions();
    const { id } = useParams()
    const pathname = useLocation()
    const navigate = useNavigate()
    const authState = useSelector((state) => state.auth);
    const { socialToken, token } = authState?.userDetails;
    const getUserData = useSelector((state) => state.auth.userProfile);
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [dob, setDob] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [success, setSuccess] = useState('');

    const [updateSucces , setUpdateSucces] = useState(false);

    const verifiedResponse = JSON.parse(localStorage.getItem('verifiedResponse'))


    const dispatch = useDispatch();


    const handleKeyUp = (e) => {
        const inputValue = e.target.value;
        localStorage.setItem('email', inputValue);
    };


    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onSubmit",
    });
    useEffect(() => {
        dispatch(auth.getCountryCode());

    }, [])

    const handleModalChange = () => {
        handleCloseWhatsappModal()
    }


    useEffect(()=>{

        setName(getUserData?.user_data?.name)
        setDob(getUserData?.user_data?.dob)

    },[getUserData])

    const onSubmit = (data) => {
        const requestData = {
            ...data,
            name: data.name.trim(),
            password: data.password.trim(),

          
        };

        dispatch({ type: authConstants.EDIT_PROFILE_REQUEST });
        dispatch(auth.editProfile(requestData, { Token: verifiedResponse?.hash }))
            .then((data) => {

                if(data?.type == 'EDIT_PROFILE_SUCCESS'){
                    dispatch(auth.getEditProfile({ token: verifiedResponse?.hash }))
                    handleCreateAccountModal()
                    let planId = localStorage.getItem('membershipId');
                    if (planId){
                        navigate(`/membership/${planId}`)
                    }
        
                    localStorage.removeItem('membershipId');

                }
            
               

            })
            .catch((error) => {

            });
        
    }



    const today = new Date().toISOString().split('T')[0];

    document.getElementById('dobInput')?.setAttribute('max', today);









    return (
        <>
            <Modal
                show={showCreateAccountModal}
                // onHide={handleCreateAccountModal}
                animation={false}
                centered
                size="md"
                className="modal signup-modal fans-registermodal whatsapp-login-page whatsappModal create-account-modal "
            >
             
                <Modal.Body>
                    <div className="d-flex align-items-center justify-content-between">
                        <ReactBtn
                            variant="link"
                            // onClick={handleCreateAccountModal}
                            className="close"
                            style={{ background: 'none', border: 'none', textAlign: 'end' }}
                        >
                            <img src="/assets/img/cross.svg" alt="Close" />
                        </ReactBtn>
                    </div>
                    <div className="modal-body">
                        <div className="signup-modal-area">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 p-0">
                                    <div className="form-area">
                                        <div className="title" style={{ textAlign: 'center' }}>
                                            <h4>Hey, we want to know <br /> you better!</h4>
                                        </div>
                                        <form className="form-section" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="form-group ">
                                                <FormInput
                                                    onkeydown="return /[a-z]/i.test(event.key)"
                                                    id="floatingInputValue"
                                                    register={register('name')}
                                                    name="name"
                                                    type="text"
                                                    autoComplete="off"
                                                    identity="name"
                                                    placeholder="Enter full name"
                                                    error={errors?.name}
                                                    defaultValue={getUserData?.user_data?.name}
                                                    autoFocus={
                                                        !name && !errors?.name?.message
                                                    }
                                                />

                                            </div>
                                            {/* <div className="form-group ">
                                                <FormInput
                                                    type="date"
                                                    className="form-control date-pick"
                                                    name="dob"
                                                    placeholder="Select your date of birth"
                                                    // onChange={(e) => handleDateChange(e.target.value)}
                                                    id="dobInput"
                                                    format="DD/MM/YYYY"
                                                    register={register('dob')}
                                                    maxdate={new Date()}
                                                    error={errors?.dob}
                                                    defaultValue={getUserData?.user_data?.dob}
                                                    autoFocus={
                                                        !dob && !errors?.dob?.message
                                                    }
                                                />

                                            </div> */}
                                            <div className="form-group ">
                                                <FormInput
                                                    id="floatingInputValue"
                                                    register={register('email')}
                                                    error={errors?.email}
                                                    name="email"
                                                    type="text"
                                                    identity="email"
                                                    autoComplete="off"
                                                    placeholder="Enter email address"
                                                    onKeyUp={(e) => handleKeyUp(e)}
                                                    defaultValue={getUserData?.user_data?.email}
                
                                                />
                                            </div>
                                            <div className="form-group  remove-space-b">
                                                <FormInput
                                                    id="floatingInputValue"
                                                    register={register('password')}
                                                    name="password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    identity="password"
                                                    autoComplete="off"
                                                    placeholder="Create new password"
                                                    error={errors?.password}
                                                />
                                                <div className="input-group-addon">
                                                    <Link to id="showhide" onClick={() => setShowPassword(!showPassword)}><i className={`fa-regular fa-eye${showPassword === true ? '' : '-slash'}`} aria-hidden="true"></i></Link>
                                                </div>
                                                <div className="password-limitation-msg">
                                                    {success && password.length >= 8 && <p class="correct_pass"><img src="assets/img/check.svg" alt="" />{success}</p>}
                                                </div>
                                            </div>

                                            <div type="submit" className="submit-btn mt-4">
                                                {authState.regSubmitted === true ? <Button className='btn action-btn' label='Loading...' disabled /> : <Button className='btn action-btn' label='Get Started' />}
                                            </div>


                                        </form>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CreateAccountModal;
