
import { authService } from "../Adapters/auth.services";
import { toast } from "react-toastify";
import { authConstants } from "../Constants/Auth";
import CustomErrorToast from "../components/CustomToaster/CustomErrorToast";
import CustomSuccessToast from "../components/CustomToaster/CustomSuccessToaster";

export const auth = {
    signIn,
    signUp,
    signOut,
    forgotPassword,
    OtpVerify,
    resetPassword,
    ChangePassword,
    contactUs,
    editProfile,
    newsLetterSubscription,
    requestSongsLessons,
    getEditProfile,
    updateForgotPasswordFlag,
    updateOtpFlag,
    resetFlag,
    updateContactFlag,
    updateRegisterFlag,
    updateChangeFlag,
    resetFlags,
    socialLogin,
    updateNewsletterFlag,
    getCountryCode,
    verifyToken,
    verifyEmail,
    resendCode,
    userStreak,
    notification,
    studentLogin,
    studentSocialLogin,
    studentVerification,
    sendWhatsappOtp,
    VerifyWhatsappOtp,
}


async function handleRequest(endpoint, params, successType, failureType, headers = null) {
    try {
        const response = headers
            ? await authService.sendTokenInHeader(endpoint, params, headers)
            : await authService.sendRequest(endpoint, params);

        if (response?.status === 200 && response?.data?.code === "SUCCESS") {
            if (
                response?.data?.message === "Login successful!" ||
                response?.data?.message === "Logout successful!"
            ) {
                console.log('');
            } else {
                toast(<CustomSuccessToast message={response?.data?.message} />)
            }
            return { type: successType, payload: response?.data };
        } else if (response?.status === 201 && response?.data?.code === "SUCCESS") {
            toast(<CustomSuccessToast message={response?.data?.message} />)
            return { type: successType, payload: response?.data };
        } else if (response?.status === 404 && response?.data?.code === "ERROR") {
            if (response?.data?.errors) {
                for (const key in response?.data?.errors) {
                    if (response?.data?.errors.hasOwnProperty(key)) {
                        response?.data?.errors[key].forEach((errorMessage) => {
                            toast(<CustomErrorToast message={errorMessage} />);
                        });
                    }
                }
            } else {
                if (response?.data?.message == "User not found or invalid token.") {
                    console.log('error');
                } else {
                    toast(<CustomErrorToast message={response?.data?.message} />);
                }

            }

            return { type: failureType, payload: response?.data };
        } else if (response?.status === 400 && response?.data?.code === "ERROR") {
            if (response?.data?.errors) {
                for (const key in response?.data?.errors) {
                    if (response?.data?.errors.hasOwnProperty(key)) {
                        response?.data?.errors[key].forEach((errorMessage) => {
                            toast(<CustomErrorToast message={errorMessage} />);
                        });
                    }
                }
            } else {
                toast(<CustomErrorToast message={response?.data?.message} />);
            }
            return { type: failureType, payload: response?.data };
        } else if (response?.status === 401 && response?.data?.code === "ERROR") {
            if (response?.data?.errors) {
                for (const key in response?.data?.errors) {
                    if (response?.data?.errors.hasOwnProperty(key)) {
                        response?.data?.errors[key].forEach((errorMessage) => {
                            toast(<CustomErrorToast message={errorMessage} />);
                        });
                    }
                }
            } else {
                toast(<CustomErrorToast message={response?.data?.message} />);
            }
            return { type: failureType, payload: response?.data };
        } else if (response?.status === 500) {
            toast(<CustomErrorToast message={'Internal server error'} />);
            return { type: failureType, payload: response?.data };
        } else {
            toast(<CustomErrorToast message={'Invalid api request'} />);
            return { type: failureType, payload: response?.data };
        }
    } catch (error) {
        toast(<CustomErrorToast message={'Some error occured'} />);
    }
}

async function signIn(params) {

    if (params.isRemember) {
        localStorage.setItem('data', JSON.stringify(params))
    } else {
        localStorage.setItem('data', null)
    }
    return handleRequest("/api/sign-in", params, authConstants.SIGNIN_SUCCESS, authConstants.SIGNIN_FAILURE);

}

async function signUp(params) {
    return handleRequest("/api/sign-up", params, authConstants.SIGNUP_SUCCESS, authConstants.SIGNUP_FAILURE);

}

async function signOut(params, headers) {
    return handleRequest("/api/logout", params, authConstants.SIGNOUT_SUCCESS, authConstants.SIGNOUT_FAILURE, headers);

}

async function forgotPassword(params) {
    return handleRequest("/api/forget-password", params, authConstants.FORGOT_PASSWORD_SUCCESS, authConstants.FORGOT_PASSWORD_FAILURE);

}

async function resendCode(params) {
    return handleRequest("/api/forget-password", params, authConstants.RESEND_SUCCESS, authConstants.RESEND_FAILURE);

}

async function OtpVerify(params) {
    return handleRequest("/api/verify-otp", params, authConstants.OTP_VERIFY_SUCCESS, authConstants.OTP_VERIFY_FAILURE);

}

async function resetPassword(params) {
    return handleRequest("/api/reset-password", params, authConstants.RESET_PASSWORD_SUCCESS, authConstants.RESET_PASSWORD_FAILURE);

}

async function ChangePassword(params, headers) {
    return handleRequest("/api/change-password", params, authConstants.CHANGE_PASSWORD_SUCCESS, authConstants.CHANGE_PASSWORD_FAILURE, headers);

}

async function contactUs(params) {
    return handleRequest("/api/contact-us/", params, authConstants.CONTACT_SUCCESS, authConstants.CONTACT_FAILURE);

}

async function editProfile(params, headers) {
    return handleRequest("/api/update-profile", params, authConstants.EDIT_PROFILE_SUCCESS, authConstants.EDIT_PROFILE_FAILURE, headers);

}

async function newsLetterSubscription(params) {
    return (dispatch) => {
        dispatch(request());
        authService
            .newsLetterSubscription('/api/newsletter', params)
            .then((response) => {
                dispatch(success(response.data));

                if (response?.status === 200 && response?.data?.code === "SUCCESS") {
                    toast(<CustomSuccessToast message={response?.data?.message} />);
                } else if ((response?.status === 400 || response?.status === 404) && response?.data?.code === "ERROR") {
                    if (response?.data?.errors) {
                        for (const key in response?.data?.errors) {
                            if (response?.data?.errors.hasOwnProperty(key)) {
                                response?.data?.errors[key].forEach((errorMessage) => {
                                    toast(<CustomErrorToast message={errorMessage} />);
                                });
                            }
                        }
                    } else {
                        toast(<CustomErrorToast message={response?.data?.message} />);
                    }
                }
            })
            .catch((error) => {
                dispatch(errorProfile(error));
            });
    };

    function errorProfile(error) {
        return { type: authConstants.NEWSLETTER_SUBSCRIPTION_FAILURE, error: error };
    }

    function request() {
        return { type: authConstants.NEWSLETTER_SUBSCRIPTION_REQUEST };
    }

    function success(data) {
        return { type: authConstants.NEWSLETTER_SUBSCRIPTION_SUCCESS, payload: data };
    }

}

async function socialLogin(params, referralCode , navigate) {
    params.referral_code = referralCode
    return (dispatch) => {
        dispatch(request());
        authService
            .sendRequest('/api/social-login', params)
            .then((response) => {
                dispatch(success(response.data));

                if (response?.status === 200 && response?.data?.code === "SUCCESS") {
                    if(navigate){
                        if (response?.data?.new_user) {
                            navigate("/guitar-lessons?new_conversion");
                        } else{
                            navigate('/dashboard')

                        }
                    }
                } else if ((response?.status === 400 || response?.status === 404) && response?.data?.code === "ERROR") {
                    if (response?.data?.errors) {
                        for (const key in response?.data?.errors) {
                            if (response?.data?.errors.hasOwnProperty(key)) {
                                response?.data?.errors[key].forEach((errorMessage) => {
                                });
                            }
                        }
                    } else {

                    }
                } else if (response?.data?.errors) {
                    for (const key in response?.data?.errors) {
                        if (response?.data?.errors.hasOwnProperty(key)) {
                            response?.data?.errors[key].forEach((errorMessage) => {
                                toast(<CustomErrorToast message={errorMessage} />);

                            });
                        }
                    }
                }
            })
            .catch((error) => {
                dispatch(errorSocial(error));
            });
    };

    function errorSocial(error) {
        return { type: authConstants.SOCIAL_LOGIN_FAILURE, error: error };
    }

    function request() {
        return { type: authConstants.SOCIAL_LOGIN_REQUEST };
    }

    function success(data) {
        return { type: authConstants.SOCIAL_LOGIN_SUCCESS, payload: data };
    }

}

async function studentSocialLogin(params) {

    return (dispatch) => {
        dispatch(request());
        authService
            .sendRequest('/api/student-social-login', params)
            .then((response) => {
                dispatch(success(response.data));

                if (response?.status === 200 && response?.data?.code === "SUCCESS") {
                } else if ((response?.status === 400 || response?.status === 404) && response?.data?.code === "ERROR") {
                    if (response?.data?.errors) {
                        for (const key in response?.data?.errors) {
                            if (response?.data?.errors.hasOwnProperty(key)) {
                                response?.data?.errors[key].forEach((errorMessage) => {
                                });
                            }
                        }
                    } else {

                    }
                }
            })
            .catch((error) => {
                dispatch(errorSocial(error));
            });
    };

    function errorSocial(error) {
        return { type: authConstants.SOCIAL_LOGIN_FAILURE, error: error };
    }

    function request() {
        return { type: authConstants.SOCIAL_LOGIN_REQUEST };
    }

    function success(data) {
        return { type: authConstants.SOCIAL_LOGIN_SUCCESS, payload: data };
    }

}

async function verifyEmail(params) {
    return (dispatch) => {
        dispatch(request());
        authService
            .newsLetterSubscription('/api/verify-existing-user', params)
            .then((response) => {
                dispatch(success(response.data));

                if (response?.status === 200 && response?.data?.code === "SUCCESS") {
                    toast(<CustomSuccessToast message={response?.data?.message} />);
                } else if ((response?.status === 400 || response?.status === 404) && response?.data?.code === "ERROR") {
                    if (response?.data?.errors) {
                        for (const key in response?.data?.errors) {
                            if (response?.data?.errors.hasOwnProperty(key)) {
                                response?.data?.errors[key].forEach((errorMessage) => {
                                    toast(<CustomErrorToast message={errorMessage} />);
                                });
                            }
                        }
                    } else {
                        toast(<CustomErrorToast message={response?.data?.message} />);
                    }
                }
            })
            .catch((error) => {
                dispatch(errorVerify(error));
            });
    };

    function errorVerify(error) {
        return { type: authConstants.VERIFY_EMAIL_FAILURE, error: error };
    }

    function request() {
        return { type: authConstants.VERIFY_EMAIL_REQUEST };
    }

    function success(data) {
        return { type: authConstants.VERIFY_EMAIL_SUCCESS, payload: data };
    }

}

async function requestSongsLessons(params) {
    return handleRequest("/api/song-request", params, authConstants.REQUEST_SONGLESSON_SUCCESS, authConstants.REQUEST_SONGLESSON_FAILURE);

}

async function verifyToken(params, headers) {
    return handleRequest("/api/verify-email", params, authConstants.VERIFY_TOKEN_SUCCESS, authConstants.VERIFY_TOKEN_FAILURE, headers);

}

async function notification(params, headers) {
    return handleRequest("/api/notification", params, authConstants.NOTIFICATION_SUCCESS, authConstants.NOTIFICATION_FAILURE, headers);

}

async function studentLogin(params) {
    return (dispatch) => {
        dispatch(request());
        authService
            .sendRequest('/api/student-sign-in', params)
            .then((response) => {
                dispatch(success(response.data));

                if (response?.status === 200 && response?.data?.code === "SUCCESS") {
                    toast(<CustomSuccessToast message={response?.data?.message} />);
                } else if ((response?.status === 400 || response?.status === 404) && response?.data?.code === "ERROR") {
                    if (response?.data?.errors) {
                        for (const key in response?.data?.errors) {
                            if (response?.data?.errors.hasOwnProperty(key)) {
                                response?.data?.errors[key].forEach((errorMessage) => {
                                    toast(<CustomErrorToast message={errorMessage} />);
                                });
                            }
                        }
                    } else {
                        toast(<CustomErrorToast message={response?.data?.message} />);
                    }
                }
            })
            .catch((error) => {
                dispatch(errorStudent(error));
            });
    };

    function errorStudent(error) {
        return { type: authConstants.STUDENT_LOGIN_FAILURE, error: error };
    }

    function request() {
        return { type: authConstants.STUDENT_LOGIN_PENDING };
    }

    function success(data) {
        return { type: authConstants.STUDENT_LOGIN_SUCCESS, payload: data };
    }

}

async function studentVerification(params) {
    return (dispatch) => {
        dispatch(request());
        authService
            .studentVerification(`/api/student-verification?id=${params}`)
            .then((response) => {
                dispatch(success(response.data));
            })
            .catch((error) => {
                dispatch(errorStudentVerification(error));
            });
    };

    function errorStudentVerification(error) {
        return { type: authConstants.STUDENT_VERIFICATION_FAILURE, error: error };
    }

    function request() {
        return { type: authConstants.STUDENT_VERIFICATION_PENDING };
    }

    function success(data) {
        return { type: authConstants.STUDENT_VERIFICATION_SUCCESS, payload: data };
    }


}

async function getEditProfile(params) {
    return (dispatch) => {
        dispatch(request());
        authService
            .getEditProfileData('/api/user-profile', params)
            .then((response) => {
                dispatch(success(response.data));
            })
            .catch((error) => {
                dispatch(errorProfile(error));
            });
    };

    function errorProfile(error) {
        return { type: authConstants.GET_EDIT_PROFILE_FAILURE, error: error };
    }

    function request() {
        return { type: authConstants.GET_EDIT_PROFILE_PENDING };
    }

    function success(data) {
        return { type: authConstants.GET_EDIT_PROFILE_SUCCESS, payload: data };
    }
}

function getCountryCode(params) {
    return (dispatch) => {
        dispatch(request());
        authService
            .getCountryCode('/api/country-code', params)
            .then((response) => {
                dispatch(success(response.data));
            })
            .catch((error) => {
                dispatch(errorCountryCode(error));
            });
    };

    function errorCountryCode(error) {
        return { type: authConstants.GET_COUNTRYCODE_FAILURE, error: error };
    }

    function request() {
        return { type: authConstants.GET_COUNTRYCODE_PENDING };
    }

    function success(data) {
        return { type: authConstants.GET_COUNTRYCODE_SUCCESS, payload: data };
    }
}

function userStreak(params) {
    return (dispatch) => {
        dispatch(request());
        authService
            .getUserStreak("/api/check-user", params)
            .then((response) => {
                dispatch(success(response.data));
            })
            .catch((error) => {
                dispatch(errorStreak(error));
            });
    };

    function errorStreak(error) {
        return { type: authConstants.STREAK_FAILURE, error: error };
    }

    function request() {
        return { type: authConstants.STREAK_PENDING };
    }

    function success(data) {
        return { type: authConstants.STREAK_SUCCESS, payload: data };
    }
}

async function updateForgotPasswordFlag(email) {
    return { type: authConstants.UPDATE_FORGOT_PASS_FLAG, payload: email };
}

async function updateOtpFlag(email) {
    return { type: authConstants.UPDATE_OTP_VERIFY_FLAG, payload: email };
}

async function resetFlag(email) {
    return { type: authConstants.RESET_FLAG, payload: email };
}

async function updateContactFlag() {
    return { type: authConstants.UPDATE_CONTACT_FLAG };
}

async function updateRegisterFlag(email) {
    return { type: authConstants.UPDATE_REGISTER_FLAG, payload: email };
}

async function updateChangeFlag(email) {
    return { type: authConstants.UPDATE_REGISTER_FLAG, payload: email };
}

async function resetFlags() {
    return { type: authConstants.RESET_FLAGS };
};

async function updateNewsletterFlag(email) {
    return { type: authConstants.UPDATE_NEWSLETTER_FLAG, payload: email };
}





async function sendWhatsappOtp(params, navigate, handleModalChange , isFromRegistration) {
    const url = `/api/otp/send`
    return (dispatch) => {
        dispatch(request());
        authService.postWhatsappData(url, params)
            .then((response) => {
                if (response?.status == 200) {
                    dispatch(success(response?.data));
                    toast(<CustomSuccessToast message={"OTP sent sucessfully"} />);
                    localStorage.removeItem('whatsappSentResponse')
                    localStorage.setItem('whatsappSentResponse', JSON.stringify(response?.data))
                    if (navigate && !isFromRegistration) {
                        navigate('/signin-whatsapp?otp=true');
                    }
                    if(isFromRegistration){
                        navigate('/signup-whatsapp?otp=true');
                    }

                    if (handleModalChange) {
                        handleModalChange()
                    }

                } else {
                    toast(<CustomSuccessToast message={response?.response?.data?.description || response?.data?.message} />);
                    dispatch(errors(response?.response));
                }
            })
            .catch((error) => {
                dispatch(errors(error));
                toast(<CustomErrorToast message={error} />);

            });
    };

    function errors(error) {
        return { type: authConstants.SEND_WHATSAPP_OTP_FAILURE, error: error };
    }

    function request() {
        return { type: authConstants.SEND_WHATSAPP_OTP_PENDING };
    }

    function success(data) {
        return { type: authConstants.SEND_WHATSAPP_OTP_SUCCESS, payload: data };
    }


}


async function VerifyWhatsappOtp(params, navigate) {


    const url = `/api/otp/verify`
    return (dispatch) => {
        dispatch(request());
        authService.postWhatsappData(url, params)
            .then((response) => {
                if (response?.status == 200) {
                    dispatch(success(response.data));
                    toast(<CustomSuccessToast message={"OTP verified sucessfully"} />);
                    localStorage.removeItem('verifiedResponse')
                    localStorage.setItem('verifiedResponse', JSON.stringify(response?.data))
                 

                } else {
                    toast(<CustomSuccessToast message={response?.response?.data?.description|| response?.data?.message} />);
                    dispatch(errors(response?.response));

                }
            })
            .catch((error) => {
                dispatch(errors(error));
                toast(<CustomErrorToast message={error} />);
            });
    };

    function errors(error) {
        return { type: authConstants.VERIFY_WHATSAPP_OTP_FAILURE, error: error };
    }

    function request() {
        return { type: authConstants.VERIFY_WHATSAPP_OTP_PENDING };
    }

    function success(data) {
        return { type: authConstants.VERIFY_WHATSAPP_OTP_SUCCESS, payload: data };
    }


}