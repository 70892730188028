import { post, get } from "./xhr";

export const faqService = {
    contactUs,
    getFaqData,
    getCountryCode
};

function getCountryCode(url, params) {
    return get(url, params);
}

function contactUs(url, params) {
    return post(url, params);
}

function getFaqData(url, params) {
    return get(url, params);
}