export const authConstants = {
    SIGNIN_REQUEST: "SIGNIN_REQUEST",
    SIGNIN_SUCCESS: "SIGNIN_SUCCESS",
    SIGNIN_FAILURE: "SIGNIN_FAILURE",
    
    SIGNUP_REQUEST: "SIGNUP_REQUEST",
    SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
    SIGNUP_FAILURE: "SIGNUP_FAILURE",

    FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
    FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
    FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",

    UPDATE_FORGOT_PASS_FLAG: "UPDATE_FORGOT_PASS_FLAG",
    UPDATE_OTP_VERIFY_FLAG: " UPDATE_OTP_VERIFY_FLAG",

    OTP_VERIFY_SUCCESS: "OTP_VERIFY_SUCCESS",
    OTP_VERIFY_REQUEST: "OTP_VERIFY_REQUEST",
    OTP_VERIFY_FAILURE: "OTP_VERIFY_FAILURE",

    RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",

    CONTACT_REQUEST: "CONTACT_REQUEST",
    CONTACT_SUCCESS: "CONTACT_SUCCESS",
    CONTACT_FAILURE: "CONTACT_FAILURE",

    UPDATE_CONTACT_FLAG: "UPDATE_REGISTER_FLAG",

    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
    CHANGE_PASSWORD_REQUEST:"CHANGE_PASSWORD_REQUEST",
    
    SIGNOUT_REQUEST: "SIGNOUT_REQUEST",
    SIGNOUT_SUCCESS: "SIGNOUT_SUCCESS",
    SIGNOUT_FAILURE: "SIGNOUT_FAILURE",

    EDIT_PROFILE_REQUEST: "EDIT_PROFILE_REQUEST",
    EDIT_PROFILE_SUCCESS: "EDIT_PROFILE_SUCCESS",
    EDIT_PROFILE_FAILURE: "EDIT_PROFILE_FAILURE",

    GET_EDIT_PROFILE_FAILURE: "GET_EDIT_PROFILE_FAILURE",
    GET_EDIT_PROFILE_PENDING: "GET_EDIT_PROFILE_PENDING",
    GET_EDIT_PROFILE_SUCCESS: "GET_EDIT_PROFILE_SUCCESS",

    UPDATE_REGISTER_FLAG: "UPDATE_REGISTER_FLAG",
    UPDATE_FORGOT_PASS_FLAG: "UPDATE_FORGOT_PASS_FLAG",

    RESET_FLAG: "RESET_FLAG",

    NEWSLETTER_SUBSCRIPTION_SUCCESS: "NEWSLETTER_SUBSCRIPTION_SUCCESS",
    NEWSLETTER_SUBSCRIPTION_FAILURE: "NEWSLETTER_SUBSCRIPTION_FAILURE",
    NEWSLETTER_SUBSCRIPTION_REQUEST: "NEWSLETTER_SUBSCRIPTION_REQUEST",

    REQUEST_SONGLESSON_SUCCESS: "REQUEST_SONGLESSON_SUCCESS",
    REQUEST_SONGLESSON_REQUEST: "REQUEST_SONGLESSON_REQUST",
    REQUEST_SONGLESSON_FAILURE: "REQUEST_SONGLESSON_FAILURE",
    RESET_FLAGS: "RESET_FLAGS",

    SOCIAL_LOGIN_SUCCESS: "SOCIAL_LOGIN_SUCCESS",
    SOCIAL_LOGIN_REQUEST: "SOCIAL_LOGIN_REQUEST",
    SOCIAL_LOGIN_FAILURE: "SOCIAL_LOGIN_FAILURE",

    UPDATE_NEWSLETTER_FLAG: "UPDATE_NEWSLETTER_FLAG",

    GET_COUNTRYCODE_SUCCESS: "GET_COUNTRYCODE_SUCCESS",
    GET_COUNTRYCODE_FAILURE: "GET_COUNTRYCODE_FAILURE",
    GET_COUNTRYCODE_PENDING: "GET_COUNTRYCODE_PENDING ",

    VERIFY_TOKEN_SUCCESS: "VERIFY_TOKEN_SUCCESS",
    VERIFY_TOKEN_FAILURE: "VERIFY_TOKEN_FAILURE",
    VERIFY_TOKEN_REQUEST: "VERIFY_TOKEN_REQUEST",
    
    VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
    VERIFY_EMAIL_FAILURE: "VERIFY_EMAIL_FAILURE",
    VERIFY_EMAIL_REQUEST: "VERIFY_EMAIL_REQUEST",

    RESEND_SUCCESS: "RESEND_SUCCESS",
    RESEND_FAILURE: "RESEND_FAILURE",
    RESEND_PENDING: "RESEND_PENDING",

    STREAK_SUCCESS: "STREAK_SUCCESS",
    STREAK_FAILURE: "STREAK_FAILURE",
    STREAK_PENDING: "STREAK_PENDING",

    NOTIFICATION_PENDING: "NOTIFICATION_PENDING",
    NOTIFICATION_SUCCESS: "NOTIFICATION_SUCCESS",
    NOTIFICATION_FAILURE: "NOTIFICATION_FAILURE",

    STUDENT_LOGIN_PENDING: "STUDENT_LOGIN_PENDING",
    STUDENT_LOGIN_SUCCESS: "STUDENT_LOGIN_SUCCESS",
    STUDENT_LOGIN_FAILURE: "STUDENT_LOGIN_FAILURE",

    STUDENT_VERIFICATION_FAILURE: "STUDENT_VERIFICATION_FAILURE",
    STUDENT_VERIFICATION_SUCCESS: "STUDENT_VERIFICATION_SUCCESS",
    STUDENT_VERIFICATION_PENDING: "STUDENT_VERIFICATION_PENDING",


    SEND_WHATSAPP_OTP_FAILURE: "SEND_WHATSAPP_OTP_FAILURE",
    SEND_WHATSAPP_OTP_SUCCESS: "SEND_WHATSAPP_OTP_SUCCESS",
    SEND_WHATSAPP_OTP_PENDING: "SEND_WHATSAPP_OTP_PENDING",

    VERIFY_WHATSAPP_OTP_FAILURE: "VERIFY_WHATSAPP_OTP_FAILURE",
    VERIFY_WHATSAPP_OTP_SUCCESS: "VERIFY_WHATSAPP_OTP_SUCCESS",
    VERIFY_WHATSAPP_OTP_PENDING: "VERIFY_WHATSAPP_OTP_PENDING",

}