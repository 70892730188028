import React from 'react'
import { Link } from 'react-router-dom'

export const BobTicker = ({setIsBobShow , isBobShow , isModalActive}) => {
  return (
        <div className={`bobticker ${isBobShow ? "show" : ""}`} style={{zIndex:isModalActive && '9999' }}>
        <p>Check out our band competition <Link to={"/battle-of-bands"}>Battle of the Bands</Link></p>
        {/* <img src='/assets/img/HomeNew/cross.svg' alt='' onClick={()=>{setIsBobShow(false)}} /> */}
    </div>
  )
}
