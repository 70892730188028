import { assessmentConstants } from "../Constants/AssessmentConstants"

const initialState = { 
    assessmentData: {},
    attempt: {},
    attemptHold: {},
    chapter_level_attempt: [],
    assessmentLoading: false,
    isAssessmentComplete: false,
}

export const assessmentReducer = ( state=initialState, action ) => {
    switch(action.type) {
        case assessmentConstants.CHECK_ASSESSMENT_PENDING:
            return {
                ...state,
                assessmentData: {},
                assessmentLoading:true
            };
            break;

        case assessmentConstants. CHECK_ASSESSMENT_SUCCESS:
            let attemptData = state.attempt;
            
            if(action.payload.chapter_level_id) {
                let chapter_level_attempt = state.chapter_level_attempt;
                chapter_level_attempt[action.payload.question_number] = action.payload?.data?.is_correct;
                
                attemptData[action.payload.chapter_level_id] = chapter_level_attempt;
            }
            
            return {
                ...state,
               assessmentData: action.payload.data,
               attempt: attemptData,
               assessmentLoading:false
                
            };
            break;

        case assessmentConstants.CHECK_ASSESSMENT_FAILURE:

            return {
                ...state,
                assessmentData: {},
                assessmentLoading:false
               
            };
            break;

        case 'EMPTY_LEVEL_STATE':

            return {
                ...state,
                assessmentData: {}
               
            };
            break;

        case 'ON_LEVEL_START':
            let data = state.attempt;
            
            return {
                ...state,
                chapter_level_attempt: [],
                assessmentData: {},
                attempt: {},
                attemptHold: data
                
            };

        case 'IS_ASSESSMENTS_COMPLETE':
            return {
                ...state,
                isAssessmentComplete: action.payload,
            };

        default:
            return state;
        }
    }