export const instructorConstants = {
    GET_INSTRUCTOR_SUCCESS: "GET_INSTRUCTOR_SUCCESS",
    GET_INSTRUCTOR_FAILURE: "GET_INSTRUCTOR_FAILURE",
    GET_INSTRUCTOR_PENDING: "GET_INSTRUCTOR_PENDING",

    GET_INSTRUCTOR_DETAILS_SUCCESS: "GET_INSTRUCTOR_DETAILS_SUCCESS",
    GET_INSTRUCTOR_DETAILS_FAILURE: "GET_INSTRUCTOR_DETAILS_FAILURE",
    GET_INSTRUCTOR_DETAILS_PENDING: "GET_INSTRUCTOR_DETAILS_PENDING",

    GET_INSTRUCTOR_CATEGORY_PENDING: "GET_INSTRUCTOR_CATEGORY_PENDING",
    GET_INSTRUCTOR_CATEGORY_SUCCESS: "GET_INSTRUCTOR_CATEGORY_SUCCESS",
    GET_INSTRUCTOR_CATEGORY_FAILURE: "GET_INSTRUCTOR_CATEGORY_FAILURE",
    SET_SELECTED_CATEGORY: "SET_SELECTED_CATEGORY",

    GET_INSTRUCTOR_SONGS_PENDING: "GET_INSTRUCTOR_SONGS_PENDING",
    GET_INSTRUCTOR_SONGS_SUCCESS: "GET_INSTRUCTOR_SONGS_SUCCESS",
    GET_INSTRUCTOR_SONGS_FAILURE: "GET_INSTRUCTOR_SONGS_FAILURE",

    LIKE_SONGS_PENDING: "LIKE_SONGS_PENDING",
    LIKE_SONGS_SUCCESS: "LIKE_SONGS_SUCCESS",
    LIKE_SONGS_FAILURE: "LIKE_SONGS_FAILURE",

    GET_LIKED_SUCCESS: "GET_LIKED_SUCCESS",
    GET_LIKED_FAILURE: "GET_LIKED_FAILURE",
    GET_LIKED_PENDING: "GET_LIKED_PENDING",

    REMOVE_SONG_PENDING: "REMOVE_SONG_PENDING",
    REMOVE_SONG_SUCCESS: "REMOVE_SONG_SUCCESS",
    REMOVE_SONG_FAILURE: "REMOVE_SONG_FAILURE"

}
