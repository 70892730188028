import React, { useEffect, useLayoutEffect, useState } from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Personalization from '../components/Common/Personalization/Index'
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getH1Content } from '../components/Header/seoData'
import useWindowDimensions from '../Helpers/useWindowDimensions'
import { BobTicker } from '../components/Header/BobTicker/BobTicker'

const Layout = ({ children }) => {

    const dispatch = useDispatch();
    const { pathname } = useLocation()
    const { windowWidth } = useWindowDimensions();


    const { showPersonalization } = useSelector((state) => state.personalization);
    const startCourse = useSelector((state) => state.lesson.startCourse);
    const [isModalActive, setIsModalActive] = useState(false)
    const [isBlogPage, setIsBlogPage] = useState(false)
    const [isBobShow, setIsBobShow] = useState(true)


    const [h1Content, setH1Content] = useState("");
    const instructor = useSelector((state) => state.instructor);
    const details = instructor?.instructorData?.data?.instructor;


    useLayoutEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        dispatch({ type: 'CHANGE_PERSONALIZATION_STATE', payload: false });
    }, [])

    useEffect(() => {

        if (pathname.includes('/battle-of-bands')) {
            setIsBobShow(false);
        } else {
            if(windowWidth < 994){
                setIsBobShow(false)
            }else{
                setIsBobShow(true)
            }
        }
    

    
    }, [windowWidth , pathname])

    useEffect(() => {
        const navbar = document.getElementById('mainNavbar');

        if(navbar)
        if (showPersonalization) {
            setIsModalActive(true);
            document.querySelector('body').style.overflow = 'hidden'
            if (windowWidth < 800) {
                navbar.style.zIndex = "1"
            } else {
                navbar.style.zIndex = "9999"
            }
        } else {
            document.querySelector('body').style.overflow = 'auto'
            navbar.style.zIndex = "999";
        }
    }, [showPersonalization, windowWidth])

    // Function to open the personalization component
    const openPersonalization = () => {
        dispatch({ type: 'CHANGE_PERSONALIZATION_STATE', payload: true });
        setIsModalActive(true);

        if (document.getElementById('personalization-modal-main')) {
            document.getElementById('personalization-modal-main').style.display = 'block';
        }
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const hasPersonalisation = urlParams.has('personalisation');

        // If the query parameter is present, trigger the button click
        if (hasPersonalisation) {
            openPersonalization();
        }
    }, [])


    useEffect(() => {
        var instrumentName = pathname.split('/')[1].split('-')[0].toLowerCase();
        if(instrumentName == 'free') instrumentName = pathname.split('/')[1]?.split('-')[1].toLowerCase()
        instrumentName = instrumentName.charAt(0).toUpperCase() + instrumentName.slice(1);

        let newH1Content;

        if (getH1Content(pathname) !== null) {
            newH1Content = getH1Content(pathname);
        } else {
            if (pathname === `/instructors/${details?.hash}`) {
                newH1Content = `${details?.name}-${details?.specialization}`;
            }
            else if (startCourse?.type === 'songs' && pathname !== `/instructors/${details?.hash}`) {
                newH1Content = startCourse?.lessons?.length > 0 ? `${startCourse?.title} ${instrumentName} Song Lesson` : startCourse?.title;

            } else if (startCourse?.type === 'chords' && pathname !== `/instructors/${details?.hash}`) {
                newH1Content = `${startCourse?.title} Chords`

            } else if (startCourse?.type === 'lessons' && pathname !== `/instructors/${details?.hash}`) {
                if (startCourse?.level == 2) {
                    newH1Content = startCourse?.free == 1 ? `${startCourse?.title}` : `${startCourse?.title} ${instrumentName} Lesson`;
                } else if (startCourse?.level > 2) {
                    newH1Content = `${startCourse?.title}`;
                }

            }
        }
        setH1Content(newH1Content);
    }, [pathname, startCourse, details]);


    useEffect(() => {
        const paths = ["blogs"];
        setIsBlogPage(paths.some(path => pathname.split("/").includes(path)));

        if (/^\/battle-of-bands\/fanpage\/\d+$/.test(pathname)) {
            setIsBlogPage(true)
        }
    }, [pathname]);

    return (
        <div id='main'>
            <Header onGetStartedClick={openPersonalization} setIsModalActive={setIsModalActive}  setIsBobShow={setIsBobShow} isBobShow={isBobShow} isModalActive={isModalActive}   />
            <main style={{ minHeight: '800px' }}>
                {!isBlogPage && <>
                    {h1Content !== '404' && <h1 className='d-none'>{h1Content}</h1>}
                </>
                }
                {children}
                {showPersonalization && isModalActive && <Personalization setIsModalActive={setIsModalActive} isBobShow={isBobShow} />}
            </main>

            <Footer />

        </div>
    )
}


export default Layout
