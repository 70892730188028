import React from 'react'

const TopPerformer = ({ bobData }) => {


    return (
        <>

            <div className='top-performers' style={{ alignItems: bobData?.top_performers?.data?.length === 0 && 'center' }}>
                <div className='sections' style={{ paddingTop: bobData?.top_performers?.data?.length === 0 && '0px' }}>
                    {
                        bobData?.top_performers?.data?.length === 0 ? <h3>Top performers compete to win exciting awards and prices</h3> :
                            <>
                                <h3>Top performers compete to win</h3>
                                <ol>
                                    {bobData?.top_performers?.data?.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))
                                    }
                                </ol>
                            </>
                    }

                </div>
                <img src='/assets/img/BobNarayana/trophy.png' alt='' />
            </div>

        </>

    )
}

export default TopPerformer