import { post, get, postWhatsAppData } from "./xhr";

export const authService = {
  sendRequest,
  sendTokenInHeader,
  getEditProfileData,
  getCountryCode,
  getUserStreak,
  newsLetterSubscription,
  studentVerification,
  postWhatsappData,
  getData
};

function sendRequest(url, params) {
  return post(url, params);
}

function sendTokenInHeader(url, params, headers) {
  return post(url, params, headers);
}

function getEditProfileData(url, headers) {
  return get(url, headers);
}

function getCountryCode(url, headers) {
  return get(url, headers);
}

function getUserStreak(url, headers) {
  return get(url, headers);
}

function newsLetterSubscription(url, params) {
  return post(url, params);
}

function studentVerification(url, params) {
  return get(url, params);
}


function postWhatsappData(url , params){
  return post(url, params);

}

function getData(url , params){
  return get(url, params);

}