import {webinarService} from '../Adapters/webinar.service'
import {WebinarConstants} from '../Constants/WebinarConstants'
import CustomSuccessToast from '../components/CustomToaster/CustomSuccessToaster';
import CustomErrorToast from '../components/CustomToaster/CustomErrorToast';
import { toast } from "react-toastify";

export const webinarAction= {
    getWebinar,
    saveWebinarIntent,
    getTestinomialData
    
}

function getWebinar(params, headers) {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/webinars`);
    for (let key in params) {
        url.searchParams.append(key, params[key]);
    }

    return (dispatch) => {
        dispatch(request());
        webinarService
        .getWebinar(url.href, headers)
        .then((response) => {
            dispatch(success(response?.data?.data));
        })
        .catch((error) => {
            dispatch(errorsSeo(error));
        });
    };
  
    function errorsSeo(error) {
        return { type: WebinarConstants.GET_WEBINAR_FAILURE, error: error };
    }
  
    function request() {
        return { type: WebinarConstants.GET_WEBINAR_PENDING };
    }
  
    function success(data) {
        return { type: WebinarConstants.GET_WEBINAR_SUCCESS, payload: data };
    }
}
async function saveWebinarIntent(params, headers) {
    return (dispatch) => {
        dispatch(request());
        webinarService
            .webinarSaveIntent('/api/webinar/save-intent', params, headers)
            .then((response) => {
                dispatch(success(response.data));

                if (response?.status === 200 && response?.data?.code === "SUCCESS") {
                    toast(<CustomSuccessToast message={response?.data?.message} />);
                } else if ((response?.status === 400 || response?.status === 404) && response?.data?.code === "ERROR") {
                    if (response?.data?.errors) {
                        for (const key in response?.data?.errors) {
                            if (response?.data?.errors.hasOwnProperty(key)) {
                                response?.data?.errors[key].forEach((errorMessage) => {
                                    toast(<CustomErrorToast message={errorMessage} />);
                                });
                            }
                        }
                    } else {
                        toast(<CustomErrorToast message={response?.data?.message} />);
                    }
                }
            })
            .catch((error) => {
                dispatch(errorVerify(error));
            });
    };

    function errorVerify(error) {
        return { type: WebinarConstants.SAVE_INTENT_FAILURE, error: error };
    }

    function request() {
        return { type: WebinarConstants.SAVE_INTENT_PENDING };
    }

    function success(data) {
        return { type: WebinarConstants.SAVE_INTENT_SUCCESS, payload: data };
    }

}

function getTestinomialData(params, headers) {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/testimonials`);
    for (let key in params) {
        url.searchParams.append(key, params[key]);
    }

    return (dispatch) => {
        dispatch(request());
        webinarService
        .getWebinar(url.href, headers)
        .then((response) => {
            dispatch(success(response?.data?.data));
        })
        .catch((error) => {
            dispatch(errorsSeo(error));
        });
    };
  
    function errorsSeo(error) {
        return { type: WebinarConstants.GET_TESTINOMIAL_FAILURE, error: error };
    }
  
    function request() {
        return { type: WebinarConstants.GET_TESTINOMIAL_PENDING };
    }
  
    function success(data) {
        return { type: WebinarConstants.GET_TESTINOMIAL_SUCCESS, payload: data };
    }
}