import { instructorService } from "../Adapters/instructor.service";
import { instructorConstants } from "../Constants/instructorConstants";
import { toast } from "react-toastify";
import CustomErrorToast from "../components/CustomToaster/CustomErrorToast";
import CustomSuccessToast from "../components/CustomToaster/CustomSuccessToaster";

export const instructors = {
    getInstructors,
    getInstructorDetails,
    getInstructorCategories,
    getSongs,
    likeSongs,
    getLikedData,
    removeSongs
}

async function handleRequest(endpoint, params, successType, failureType, headers = null) {
    try {
        const response = await instructorService.sendTokenInHeader(endpoint, params, headers)
            
            
        if (response?.status === 200 && response?.data?.code === "SUCCESS") {
            toast(<CustomSuccessToast message={response?.data?.message} />)
            return { type: successType, payload: response?.data };
        } else if (response?.status === 201 && response?.data?.code === "SUCCESS") {
            toast(<CustomSuccessToast message={response?.data?.message} />)
            return { type: successType, payload: response?.data };
        } else if (response?.status === 404 && response?.data?.code === "ERROR") {
            if (response?.data?.errors) {
                for (const key in response?.data?.errors) {
                    if (response?.data?.errors.hasOwnProperty(key)) {
                        response?.data?.errors[key].forEach((errorMessage) => {
                            toast(<CustomErrorToast message={response?.data?.message} />)
                        });
                    }
                }
            } else {
                if(response?.data?.message == "User not found or invalid token."){
                    console.log('error');
                } else {
                    toast(<CustomErrorToast message={response?.data?.message} />)
                }
               
            }
            
            return { type: failureType, payload: response?.data };
        } else if (response?.status === 400 && response?.data?.code === "ERROR") {
            if (response?.data?.errors) {
                for (const key in response?.data?.errors) {
                    if (response?.data?.errors.hasOwnProperty(key)) {
                        response?.data?.errors[key].forEach((errorMessage) => {
                            toast(<CustomErrorToast message={response?.data?.message} />)
                        });
                    }
                }
            } else {
                toast.error(response?.data?.message);
            }
            return { type: failureType, payload: response?.data };
        } else if (response?.status === 401 && response?.data?.code === "ERROR") {
            if (response?.data?.errors) {
                for (const key in response?.data?.errors) {
                    if (response?.data?.errors.hasOwnProperty(key)) {
                        response?.data?.errors[key].forEach((errorMessage) => {
                            toast(<CustomErrorToast message={response?.data?.message} />)
                        });
                    }
                }
            } else {
                toast.error(response?.data?.message);
            }
            return { type: failureType, payload: response?.data };
        } else if (response?.status === 500 ) {
            toast(<CustomErrorToast message={'Internal server error'} />)
            return { type: failureType, payload: response?.data };
        } else {
            toast(<CustomErrorToast message={'Invalid api request'} />)
            return { type: failureType, payload: response?.data };
        }
    } catch (error) {
        toast(<CustomErrorToast message={'Some error occured'} />)
    }
}

async function likeSongs(params, headers) {
    return handleRequest("/api/add-playlist", params,  instructorConstants.LIKE_SONGS_SUCCESS, instructorConstants.LIKE_SONGS_FAILURE, headers);
}

async function removeSongs(params, headers) {
    return handleRequest("/api/remove-song", params,  instructorConstants.REMOVE_SONG_SUCCESS, instructorConstants.REMOVE_SONG_FAILURE, headers);
}

function getInstructors(params) {
    let parent = (params) ? `?filter[instrument]=${params}` : '';
    
    return (dispatch) => {
        dispatch(request());
        instructorService
        .getInstructors(`/api/get-instructors${parent}`, params)
        .then((response) => {
            dispatch(success(response.data));
        })
        .catch((error) => {
            dispatch(errorInstructor(error));
        });
    };
  
    function errorInstructor(error) {
        return { type: instructorConstants.GET_INSTRUCTOR_FAILURE, error: error };
    }
  
    function request() {
        return { type: instructorConstants.GET_INSTRUCTOR_PENDING };
    }
  
    function success(data) {
        return { type: instructorConstants.GET_INSTRUCTOR_SUCCESS, payload: data };
    }
}

function getInstructorDetails(instructor) {
    
    return (dispatch) => {
        dispatch(request());
        instructorService
        .getInstructorDetails(`api/instructor-courses/${instructor}`)
        .then((response) => {
            dispatch(success(response.data));
        })
        .catch((error) => {
            dispatch(errorInstructorDetails(error));
        });
    };
  
    function errorInstructorDetails(error) {
        return { type: instructorConstants.GET_INSTRUCTOR_DETAILS_FAILURE, error: error };
    }
  
    function request() {
        return { type: instructorConstants.GET_INSTRUCTOR_DETAILS_PENDING };
    }
  
    function success(data) {
        return { type: instructorConstants.GET_INSTRUCTOR_DETAILS_SUCCESS, payload: data };
    }
}

function getInstructorCategories(instructor, params, filterType = '', headers) {
    let parent = (params) ? `?parent=${params}` : '';

    if (filterType?.difficulty) {
        parent += `&difficulty=${filterType?.difficulty}`;
    }
    if (filterType?.genre) {
        parent += `&genre=${filterType?.genre}`;
    }

    return (dispatch) => {
        dispatch(request());
        instructorService
        .getInstructorCategories(`api/instructor-courses/${instructor}${parent}`, headers)
        .then((response) => {
            dispatch(success(response.data));
        })
        .catch((error) => {
            dispatch(errorInstructorCategories(error));
        });
    };
  
    function errorInstructorCategories(error) {
        return { type: instructorConstants.GET_INSTRUCTOR_CATEGORY_FAILURE, error: error };
    }
  
    function request() {
        return { type: instructorConstants.GET_INSTRUCTOR_CATEGORY_PENDING };
    }
  
    function success(data) {
        return { type: instructorConstants.GET_INSTRUCTOR_CATEGORY_SUCCESS, payload: data };
    }
}

function getSongs(instructor, params, headers) {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/instructor-courses/${instructor}`);
    for (let key in params) {
        url.searchParams.append(key, params[key]);
    }

    return (dispatch) => {
        dispatch(request());
        instructorService
        .getInstructorCategories(url.href, headers)
        .then((response) => {
            let respData = {songs: {}, totalSongs: ''};
            const songsList = response?.data?.data?.lessons?.data ?? [];
            
            respData = {songs: songsList, totalSongs: response?.data?.data?.lessons?.total};
            dispatch(success(respData));
        })
        .catch((error) => {
            dispatch(errorInstructorSongs(error));
        });
    };
  
    function errorInstructorSongs(error) {
        return { type: instructorConstants.GET_INSTRUCTOR_SONGS_FAILURE, error: error };
    }
  
    function request() {
        return { type: instructorConstants.GET_INSTRUCTOR_SONGS_PENDING };
    }
  
    function success(data) {
        // return { type: instructorConstants.GET_INSTRUCTOR_CATEGORY_SUCCESS, payload: data };
        return { type: instructorConstants.GET_INSTRUCTOR_SONGS_SUCCESS, payload: data };
    }
}

function getLikedData(id, headers) {
    return (dispatch) => {
        dispatch(request());
        instructorService
        .getLikedData(`/api/playlist/${id}`,headers)
        .then((response) => {
            dispatch(success(response.data));
        })
        .catch((error) => {
            dispatch(errorLikedData(error));
        });
    };
  
    function errorLikedData(error) {
        return { type: instructorConstants.GET_LIKED_FAILURE, error: error };
    }
  
    function request() {
        return { type: instructorConstants.GET_LIKED_PENDING };
    }
  
    function success(data) {
        return { type: instructorConstants.GET_LIKED_SUCCESS, payload: data };
    }
}
