import {loopService} from '../Adapters/savedLoop.service'
import { toast } from "react-toastify";
import { loopConstants } from '../Constants/loopConstants';
import CustomSuccessToast from '../components/CustomToaster/CustomSuccessToaster';
import CustomErrorToast from '../components/CustomToaster/CustomErrorToast';

export const loop = {
    SavedLoop,
    getSavedLoop,
    deleteSavedLoop,
    getSavedLoopDataAllUser
}

async function SavedLoop(params, headers) {
    const response = await loopService.savedLoop("/api/add-loop", params, headers);
    
    if (response?.status === 200) {
        toast(<CustomSuccessToast message={response?.data?.message} />)
        return { type: loopConstants.SAVED_LOOP_SUCCESS, payload: response.data };
    } else {
        toast(<CustomErrorToast message={response?.data?.message} />)
        return { type: loopConstants.SAVED_LOOP_FAILURE };
    }
}

function getSavedLoop(id, params,headers) {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/loop/${id}`);
    for (let key in params) {
        url.searchParams.append(key, params[key]);
    }

    return (dispatch) => {
        dispatch(request());
        loopService
        .getSavedLoop(url.href, headers)
        .then((response) => {
            dispatch(success(response?.data?.data));
        })
        .catch((error) => {
            dispatch(errorLoop(error));
        });
    };
  
    function errorLoop(error) {
        return { type: loopConstants.GET_LOOP_FAILURE, error: error };
    }
  
    function request() {
        return { type: loopConstants.GET_LOOP_PENDING };
    }
  
    function success(data) {
        return { type: loopConstants.GET_LOOP_SUCCESS, payload: data };
    }
}

function deleteSavedLoop(id, headers) {
    return async (dispatch) => {
        dispatch(request());
        try {
            const response = await loopService.deleteSavedLoop(`/api/delete-loop?loop_id=${id}`, headers);
            dispatch(success(id));
            toast(<CustomSuccessToast message={response?.data?.message} />);
        } catch (error) {
            console.log(error)
            dispatch(errorDeleteLoop(error));
            toast(<CustomErrorToast message={error?.data?.message} />);
        }
    };
  
    function errorDeleteLoop(error) {
        return { type: loopConstants.DELETE_SAVED_LOOP_FAILURE, error: error };
    }
  
    function request() {
        return { type: loopConstants.DELETE_SAVED_LOOP_PENDING };
    }
  
    function success(data) {
        return { type: loopConstants.DELETE_SAVED_LOOP_SUCCESS, payload: data };
    }
}

function getSavedLoopDataAllUser(params, headers) {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/loops`);
    for (let key in params) {
        url.searchParams.append(key, params[key]);
    }

    return (dispatch) => {
        dispatch(request());
        loopService
        .getSavedLoop(url.href, headers)
        .then((response) => {
            dispatch(success(response?.data?.data));
        })
        .catch((error) => {
            dispatch(errorLoop(error));
        });
    };
  
    function errorLoop(error) {
        return { type: loopConstants.GET_SAVED_LOOP_FAILURE, error: error };
    }
  
    function request() {
        return { type: loopConstants.GET_SAVED_LOOP_PENDING };
    }
  
    function success(data) {
        return { type: loopConstants.GET_SAVED_LOOP_SUCCESS, payload: data };
    }
}
