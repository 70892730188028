import React, { useState, useEffect } from 'react';

const CountdownTimerNarayana = ({ aboutBands }) => {
    const [days, setDays] = useState('00');
    const [hours, setHours] = useState('00');
    const [minutes, setMinutes] = useState('00');
    const [seconds, setSeconds] = useState('00');

    useEffect(() => {
        if (aboutBands) {
            const deadline = aboutBands?.event_date_formatted;
            const getTime = () => {
                const time = Date.parse(deadline) - Date.now();

                if (time <= 0) {
                    setDays('00');
                    setHours('00');
                    setMinutes('00');
                    setSeconds('00');
                } else {
                    const days = Math.floor(time / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
                    const minutes = Math.floor((time / 1000 / 60) % 60);
                    const seconds = Math.floor((time / 1000) % 60);

                    setDays(String(days).padStart(2, '0'));
                    setHours(String(hours).padStart(2, '0'));
                    setMinutes(String(minutes).padStart(2, '0'));
                    setSeconds(String(seconds).padStart(2, '0'));
                }
            };

            const interval = setInterval(getTime, 1000);

            return () => clearInterval(interval);
        }
    }, [aboutBands]);

    return (
        <div className="countdown ">
            <p>THE EVENT BEGINS IN</p>
            <div className="time">
                <div className="time-section">
                    <span className="time-number">{!isNaN(days) ? days : '00'}</span>
                    <span className="time-text">Days</span>
                </div>
                <span className="colon">:</span>

                <div className="time-section">
                    <span className="time-number">{!isNaN(hours) ? hours : '00'}</span>
                    <span className="time-text">hours</span>
                </div>
                <span className="colon">:</span>

                <div className="time-section">
                    <span className="time-number">{!isNaN(minutes) ? minutes : '00'}</span>
                    <span className="time-text">minutes</span>
                </div>
                <span className="colon">:</span>
                <div className="time-section">
                    <span className="time-number">{!isNaN(seconds) ? seconds : '00'}</span>
                    <span className="time-text">seconds</span>
                </div>
            </div>
        </div>
    );
};

export default CountdownTimerNarayana;
