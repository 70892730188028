//Library
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { authConstants } from "../../Constants/Auth";
import { auth } from "../../Action/auth.actions";
import { membership } from "../../Action/membership.action";

const SideBar = () => {
    const dispatch = useDispatch();
    const authState = useSelector((state) => state.auth);
    const [token, setToken] = useState(authState?.userDetails?.hash ?? authState?.userDetails?.token);
    const pathName = useLocation()
    const [activeLink, setActiveLink] = useState(pathName.pathname);


    const handleActiveLink = (active) => {
        setActiveLink(active)
    }

    const logout = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        dispatch({ type: authConstants.SIGNOUT_REQUEST });
        dispatch(auth.signOut({}, { token: token }));
    }

    return (
        <>
            <div className="col-md-3 left-sidebar px-0">
                <ul>
                    <li className={activeLink === '/dashboard' ? 'active' : ''}>
                        <Link to="/dashboard" className='' onClick={() => {
                            handleActiveLink("/dashboard")
                        }} >
                            <span class="d-flex"><img class="mx-2" src={activeLink === '/dashboard' ? './assets/img/dashboardicon.svg' : './assets/img/dashboard.svg'} alt="saved" />
                                Dashboard </span>
                            <img src="./assets/img/sidebarArrow.svg" alt="saved" />
                        </Link>
                    </li>
                    <li className={activeLink === '/saved-courses' ? 'active' : ''}>
                        <Link to="/saved-courses" onClick={() => { handleActiveLink("/saved-courses") }}>
                            <span className="d-flex"><img className="mx-2" src={activeLink === '/saved-courses' ? './assets/img/feedback-yellow.svg' : './assets/img/savewidget.svg'} alt="saved" />
                                Saved courses &amp; lessons </span>
                            <img src="./assets/img/sidebarArrow.svg" alt="saved" />
                        </Link>

                    </li>
                    <li className={activeLink === '/saved-notes' ? 'active' : ''}>
                        <Link to="/saved-notes" onClick={() => { handleActiveLink("/saved-notes") }}>
                            <span className="d-flex"><img className="mx-2" src={activeLink === '/saved-notes' ? './assets/img/saved-note.png' : '/assets/img/non-active-note.svg'} alt="saved" />
                                Saved Notes and Loops</span>
                            <img src="./assets/img/sidebarArrow.svg" alt="saved" />
                        </Link>

                    </li>
                    <li className={activeLink === '/feedback-library' ? 'active' : ''}>
                        <Link to="/feedback-library" className='' onClick={() => {
                            handleActiveLink("/feedback-library")
                        }} >
                            <span className="d-flex"><img className="mx-2" src={activeLink === '/feedback-library' ? './assets/img/feedback.svg' : "./assets/img/message.svg"} alt="feedback" />
                                Feedback Library </span>
                            <img src="./assets/img/sidebarArrow.svg" alt="saved" />
                        </Link>
                    </li>

                    <li className={activeLink === '/membership-settings' ? 'active' : ''}>
                        <Link to="/membership-settings" className='' onClick={() => {
                            handleActiveLink("/membership-settings")
                        }} >
                            <span className="d-flex"><img className="mx-2" src={activeLink === '/membership-settings' ? "./assets/img/MembershipSettings/highlightedMembership.svg" : "./assets/img/membership-setting.svg"} alt="Settings" /> Membership Settings </span>
                            <img src="./assets/img/sidebarArrow.svg" alt="saved" />
                        </Link>
                    </li>
                    <li className={activeLink === '/giftCards' ? 'active' : ''}>
                        <Link to="/giftCards" onClick={() => {
                            handleActiveLink("/giftCards")
                        }} >
                            <span className="d-flex"><img className="mx-2" src={activeLink === '/giftCards' ? "./assets/img/Navigations/giftCard.svg" : "./assets/img/Navigations/giftCard.svg"} alt="refer" />
                                GiftCards </span>
                            <img src="./assets/img/sidebarArrow.svg" alt="saved" />
                        </Link>
                    </li>


                    <li className={activeLink === '/referral' ? 'active' : ''}>
                        <Link to="/referral" onClick={() => {
                            handleActiveLink("/referral")
                        }} >
                            <span className="d-flex"><img className="mx-2" src={activeLink === '/referral' ? "./assets/img/refer-active.svg" : "./assets/img/refer.svg"} alt="refer" />
                                Refer & Earn </span>
                            <img src="./assets/img/sidebarArrow.svg" alt="saved" />
                        </Link>
                    </li>
                    <li className={activeLink === '/profile-setting' ? 'active' : ''}>
                        <Link to="/profile-setting" onClick={() => {
                            handleActiveLink("/profile-setting")
                        }} >
                            <span className="d-flex"><img className="mx-2" src={activeLink === '/profile-setting' ? "assets/img/profile-yellow.svg" : "./assets/img/person.svg"} alt="profile" />
                                Profile Settings </span>
                            <img src="./assets/img/sidebarArrow.svg" alt="saved" />
                        </Link>
                    </li>
                    <li className={activeLink === '/notification' ? 'active' : ''}>
                        <Link to="/notification" onClick={() => {
                            handleActiveLink("/notification")
                        }} >
                            <span className="d-flex"><img className="mx-2" src={activeLink === '/notification' ? "./assets/img/notify-yellow.svg" : "./assets/img/notifications.svg"} alt="notify" />
                                Notification Center </span>
                            <img src="./assets/img/sidebarArrow.svg" alt="saved" />
                        </Link>
                    </li>

                    <li>
                        <Link to="javascript:void(0);" className='' onClick={() => {
                            logout()
                        }} >
                            <span className="d-flex text-danger"><img className="mx-2" src="./assets/img/new-Logout.svg" alt="logout" /> Logout </span>
                        </Link>
                    </li>

                </ul>
            </div>

            {/* <!-- mobile side nav bar --> */}
            {/* <div className="main-modal-container modal" id="mob-sidenavbar">
                <div className="mobile-sidenavbar">
                    <div className="w-100 menu">
                        <div className="d-flex justify-content-between  ">
                            <img className="mx-1" src="./assets/img/logo-mob-black.svg" alt="logo" />
                            <img src="./assets/img/cross.svg" alt="cross" onClick="fncCrossProfile('mob-sidenavbar')" />
                        </div>

                        <div className="mob-nav my-4">
                            <ul>
                                <li className="active">
                                    <Link to="/dashboard" className={activeLink === '/dashboard' ? 'active' : ''} >
                                        <span className="d-flex">
                                            <img className="mx-2" src="./assets/img/dashboardicon.svg" alt="saved" />
                                            Dashboard
                                        </span>
                                    </Link>
                                    <img src="./assets/img/arrow-left.svg" alt="saved" />
                                </li>
                                <li>
                                    <span className="d-flex"><img className="mx-2" src="./assets/img/savewidget.svg" alt="saved" />
                                        Saved courses <br />& lessons </span> <img src="./assets/img/arrow-left.svg"
                                            alt="arrow-left" />
                                </li>
                                <hr />
                                <li>
                                    <span className="d-flex"><img className="mx-2" src="./assets/img/message.svg" alt="feedback" />
                                        Feedback Library </span><img src="./assets/img/arrow-left.svg" alt="arrow-left" />
                                </li>
                                <hr />
                                <li>
                                    <span className="d-flex"><img className="mx-2" src="./assets/img/membership-setting.svg"
                                        alt="Settings" /> Membership Settings </span> <img
                                        src="./assets/img/arrow-left.svg" alt="arrow-left" />
                                </li>
                                <hr />
                                <li>
                                    <span className="d-flex"><img className="mx-2" src="./assets/img/person.svg" alt="profile" />
                                        Profile Settings </span><img src="./assets/img/arrow-left.svg" alt="arrow-left" />
                                </li>
                                <hr />
                                <li>
                                    <span className="d-flex"><img className="mx-2" src="./assets/img/notifications.svg"
                                        alt="notify" />
                                        Notification Center </span><img src="./assets/img/arrow-left.svg"
                                            alt="arrow-left" />
                                </li>
                                <hr />
                            </ul>
                        </div>
                        <ul className="position-absolute  bottom-0 mb-0 list-unstyled" >
                            <li>
                                <span className=" d-flex logoout-text"><img className="mx-2" src="./assets/img/new-Logout.svg" alt="logout" /> Logout
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> */}
        </>
    );
};

export default SideBar;
