import {WebinarConstants} from '../Constants/WebinarConstants'

const initialState = { 
    webinarData:{},
    saveWebinarIntent:{},
    saveWebinarLoading:false,
    testinomialData:[],
    webinarLoading:false
};

export const webinarReducer = ( state=initialState, action ) => {
    switch(action.type) {
        case WebinarConstants.GET_WEBINAR_PENDING:
            return {
                ...state,
                webinarData:{},
                webinarLoading:true
               
            };
            break;
            
        case WebinarConstants.GET_WEBINAR_SUCCESS:
                
            return {
                ...state,
                webinarData:action.payload,
                webinarLoading:false
                
            };

            break;

        case WebinarConstants.GET_WEBINAR_FAILURE:

            return {
                ...state,
                webinarData:{},
                webinarLoading:false,
                error: action.error,
               
                
            };
            break;

        case WebinarConstants.SAVE_INTENT_PENDING:
            return {
                ...state,
                saveWebinarIntent:{},
                saveWebinarLoading:true
               
            };
            break;
            
        case WebinarConstants.SAVE_INTENT_SUCCESS:
                
            return {
                ...state,
                saveWebinarIntent:action.payload,
                saveWebinarLoading:false
                
            };

            break;

        case WebinarConstants.SAVE_INTENT_FAILURE:

            return {
                ...state,
                saveWebinarIntent:{},
                error: action.error,
                saveWebinarLoading:false
               
                
            };
            break;

            case WebinarConstants.GET_TESTINOMIAL_PENDING:
                return {
                    ...state,
                    testinomialData:[]
                   
                };
                break;
                
            case WebinarConstants.GET_TESTINOMIAL_SUCCESS:
                    
                return {
                    ...state,
                    testinomialData:action.payload
                    
                };
    
                break;
    
            case WebinarConstants.GET_TESTINOMIAL_FAILURE:
    
                return {
                    ...state,
                    testinomialData:{},
                    error: action.error,
                   
                    
                };
                break;

        default:
            return state;
    }
    }