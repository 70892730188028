import { bandsConstants } from '../Constants/bandsConstant'

const initialState = {
    aboutBands: [],
    eventsData: [],
    zonesData: [],
    bandsFaqs: [],
    yearsImageData: {},
    urlsData: [],
    fanPageData: [],
    hallOfFameData: [],
    folowStatus: [],
    followersCount: [],
    HofPostMessage:[],

}

export const bandsReducer = (state = initialState, action) => {
    switch (action.type) {
        case bandsConstants.GET_BANDS_FAQ_PENDING:
            return {
                ...state,
                aboutBands: [],
            };
            break;

        case bandsConstants.GET_BANDS_INFO_SUCCESS:

            return {
                ...state,
                aboutBands: action.payload

            };
            break;

        case bandsConstants.GET_BANDS_INFO_FAILURE:
            return {
                ...state,
                aboutBands: [],
            };
            break;

        case bandsConstants.GET_EVENTS_PENDING:
            return {
                ...state,
                eventsData: [],
            };
            break;

        case bandsConstants.GET_EVENTS_SUCCESS:

            return {
                ...state,
                eventsData: action.payload

            };
            break;

        case bandsConstants.GET_EVENTS_FAILURE:
            return {
                ...state,
            };
            break;

        case bandsConstants.GET_ZONES_PENDING:
            return {
                ...state,
                zonesData: [],
            };
            break;

        case bandsConstants.GET_ZONES_SUCCESS:

            return {
                ...state,
                zonesData: action.payload

            };
            break;

        case bandsConstants.GET_ZONES_FAILURE:
            return {
                ...state,
            };
            break;

        case bandsConstants.GET_BANDS_FAQ_PENDING:
            return {
                ...state,
                bandsFaqs: [],
            };
            break;

        case bandsConstants.GET_BANDS_FAQ_SUCCESS:

            return {
                ...state,
                bandsFaqs: action.payload

            };
            break;

        case bandsConstants.GET_BANDS_FAQ_FAILURE:
            return {
                ...state,
            };
            break;

        case bandsConstants.GET_YEARSIMAGES_PENDING:
            return {
                ...state,
            };
            break;

        case bandsConstants.GET_YEARSIMAGES_SUCCESS:
            return {
                ...state,
                yearsImageData: action.payload
            };
            break;

        case bandsConstants.GET_YEARSIMAGES_FAILURE:
            return {
                ...state,
            };
            break;

        case bandsConstants.GET_VIDEOURL_PENDING:
            return {
                ...state,
            };
            break;

        case bandsConstants.GET_VIDEOURL_SUCCESS:
            return {
                ...state,
                urlsData: action.payload
            };
            break;

        case bandsConstants.GET_VIDEOURL_FAILURE:
            return {
                ...state,
                urlsData: []
            };
            break;


        case bandsConstants.GET_FANPAGE_DATA_PENDING:
            return {
                ...state,
            };
            break;

        case bandsConstants.GET_FANPAGE_DATA_SUCCESS:
            return {
                ...state,
                fanPageData: action.payload
            };
            break;

        case bandsConstants.GET_FANPAGE_DATA_FAILURE:
            return {
                ...state,
                fanPageData: []
            };
            break;

        case bandsConstants.GET_HALL_OF_FAMEDATA_PENDING:
            return {
                ...state,
            };
            break;

        case bandsConstants.GET_HALL_OF_FAMEDATA_SUCCESS:
            return {
                ...state,
                hallOfFameData: action.payload
            };
            break;

        case bandsConstants.GET_HALL_OF_FAMEDATA_FAILURE:
            return {
                ...state,
                hallOfFameData: []
            };
            break;
        case bandsConstants.FOLLOW_REQUEST:
            return {
                ...state,
            };
            break;

        case bandsConstants.FOLLOW_SUCESS:
            return {
                ...state,
                folowStatus: action.payload,
            };
            break;

        case bandsConstants.FOLLOW_FAILURE:
            return {
                ...state,
            };
            break;

        case bandsConstants.GET_FOLLOWER_PENDING:
            return {
                ...state,
            };
            break;

        case bandsConstants.GET_FOLLOWER_SUCESS:
            return {
                ...state,
                followersCount: action.payload,
            };
            break;

        case bandsConstants.GET_FOLLOWER_FAILURE:
            return {
                ...state,
            };
            break;

            case bandsConstants.HOF_MESSAGE_PENDING:
                return {
                    ...state,
                    HofPostMessage:[]
                };
                break;
    
            case bandsConstants.HOF_MESSAGE_SUCCESS:
                return {
                    ...state,
                    HofPostMessage: action.payload,
                };
                break;
    
            case bandsConstants.HOF_MESSAGE_FAILURE:
                return {
                    ...state,
                    HofPostMessage:[]
                };
                break;


                case 'RESET_BOB_DATA':
                return {

                    aboutBands: [],
                    eventsData: [],
                    zonesData: [],
                    bandsFaqs: [],
                    yearsImageData: {},
                    urlsData: [],
                    fanPageData: [],
                    hallOfFameData: [],
                    folowStatus: [],
                    followersCount: [],
                    HofPostMessage:[],
                }


            
        default:
            return state;
    }
}