import { post, get } from "./xhr";

export const lessonService = {
  getLessonsData,
  getLessonHistory,
  getPlaylistData,
  sendTokenInHeader,
  getRecommendedCourses,
  fetchAllCourses,
  getShowMembershipView,
  getCourseCompletionProgress,
  getSongsCount,
  getAssessmentProgress
  
};

function getLessonsData(url, headers) {
  return get(url, headers);
}

function getLessonHistory(url, headers) {
  return get(url, headers);
}

function getPlaylistData(url, headers) {
  return get(url, headers);
}

function sendTokenInHeader(url, params, headers) {
  return post(url, params, headers);
}

function getRecommendedCourses(url, headers) {
  return get(url, headers);
}

function fetchAllCourses(url) {
  return get(url);
}

function getShowMembershipView(url, headers) {
  return get(url, headers);
}

function getCourseCompletionProgress(url, headers) {
  return get(url, headers);
}

function getSongsCount(url, headers) {
  return get(url, headers);
}

function getAssessmentProgress(url, headers) {
  return get(url, headers);
}


