import { membershipConstants } from "../Constants/Membership";

const initialState = { 
    membershipLoading: false,
    error: {},
    userMembership:[],
    availablePayment: [],
    instruments: {},
    plans:{},
    membershipPlan:{},
    couponData:{},
    checkoutLoading:false,
    couponLoading:false,
    checkoutData:{},
    price:{},
    paymentDetails:[],
    cancelLoading:false,
    cancelSubscriptionData:{},
    instrumentLoading:false,
    availableCoupon:{},
    giftCardTemplates:[],
}

export const membershipReducer = ( state=initialState, action ) => {
    switch(action.type) {
        case membershipConstants.GET_MEMBERSHIP_PENDING:
            return {
                ...state,
                membershipLoading: true,
                cancelSubscriptionData:{},
                checkoutData:{}
            };
            break;

        case membershipConstants.GET_MEMBERSHIP_SUCCESS:
            return {
                ...state,
                membershipLoading: false,
                userMembership: action.payload,
                cancelSubscriptionData:{},
                checkoutData:{}
            };
            break;

        case membershipConstants.GET_MEMBERSHIP_FAILURE:
            return {
                ...state,
                membershipLoading: false,
                error: action.error,
                cancelSubscriptionData:{},
                checkoutData:{}
            };
            break;

        case membershipConstants.SUBSCRIPTION_REQUEST:
            return {
                ...state,
                submitted: true,
            };
            break;

        case membershipConstants.SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                success: action.payload,
                submitted: false,
                error: {},
            };
            break;

        case membershipConstants.SUBSCRIPTION_FAILURE:
            return {
                ...state,
                error: action.payload, 
                submitted: false,
            };
            break;

        case membershipConstants.GET_AVAILABLE_PAYMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
            break;

        case membershipConstants.GET_AVAILABLE_PAYMENT_SUCCESS:
            return {
                ...state,
                instrumentLoading: false,
                availablePayment: action.payload,
            };
            break;

        case membershipConstants.GET_AVAILABLE_PAYMENT_FAILURE:
            return {
                ...state,
                instrumentLoading: false,
                error: action.error,
            };
            break;

        case membershipConstants.GET_INSTRUMENT_REQUEST:
            return {
                ...state,
                instrumentLoading: true,
            };
            break;

        case membershipConstants.GET_INSTRUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                instruments: action.payload,
            };
            break;

        case membershipConstants.GET_INSTRUMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
            break;

        case 'SET_MEMEBERSHIP_PLAN' :
            return {
                membershipPlan:action.payload
            }

        case 'SET_MEMBERSHIP_PRICE' :
            return {
                price:action.payload
            }

        case membershipConstants.COUPON_CODE_PENDING:
            return {
                ...state,
                couponLoading: true,
                couponData:{}
            };
            break;

        case membershipConstants.COUPON_CODE_SUCCESS:
            return {
                ...state,
                couponLoading: false,
                couponData:action.payload
            };
            break;

        case membershipConstants.COUPON_CODE_FAILURE:
            return {
                ...state,
                couponLoading: false,
                couponData:action.payload,
                error: action.error,
            };
            break;

        case membershipConstants.CHECKOUT_PENDING:
            return {
                ...state,
                checkoutLoading: true,
                checkoutData:{}
                
            };
            break;

        case membershipConstants.CHECKOUT_SUCCESS:
            return {
                ...state,
                checkoutLoading: false,
                checkoutData: action.payload
            };
            break;

        case membershipConstants.CHECKOUT_FAILURE:
            return {
                ...state,
                checkoutLoading: false,
                checkoutData: {},
                error: action.error,
            };

        case membershipConstants.GET_PAYMENT_DETAILS_PENDING:
            return {
                ...state,
            };
            break;

        case membershipConstants.GET_PAYMENT_DETAILS_SUCCESS:
            return {
                ...state,
                paymentDetails: action.payload,
            };
            break;

        case membershipConstants.GET_PAYMENT_DETAILS_FAILURE:
            return {
                ...state,
                error: action.error,
            };
            break;

        case 'CHANGE_COUPON_CODE_STATE' :
            return {
                ...state,
                couponData: {},
               
            }

        case membershipConstants.CANCEL_SUBSCRIPTION_PENDING:
            return {
                ...state,
                cancelLoading: true,
                cancelSubscriptionData:{}
                
            };
            break;

        case membershipConstants.CANCEL_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                cancelLoading: false,
                cancelSubscriptionData: action.payload
            };
            break;

        case membershipConstants.CANCEL_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                cancelLoading: false,
                cancelSubscriptionData: {},
                error: action.error,
            };

        case membershipConstants.GET_AVAILABLE_COUPON_PENDING:
            return {
                ...state,
                availableCoupon:{}
                
            };
            break;

            case membershipConstants.GET_GIFTCARD_TEMPLATES_SUCCESS:
                return {
                    ...state,
                    giftCardTemplates: action.payload
                };
                break;
    
            case membershipConstants.GET_GIFTCARD_TEMPLATES_FAILURE:
                return {
                    ...state,
                    error: action.error,
                    giftCardTemplates:[]
                };
    
            case membershipConstants.GET_GIFTCARD_TEMPLATES_PENDING:
                return {
                    ...state,
                    giftCardTemplates:[]
                    
                };
                break;

        case membershipConstants.GET_AVAILABLE_COUPON_SUCCESS:
            return {
                ...state,
                availableCoupon: action.payload
            };
            break;
            case membershipConstants.RESET_COUPAN_DATA:
                return {
                    ...state,
                    couponLoading: false,
                    couponData:{},
                    paymentDetails:[]
                };
                break;

        case membershipConstants.GET_AVAILABLE_COUPON_FAILURE:
            return {
                ...state,
                availableCoupon:{}
            };
        default:
            return state;
}
}