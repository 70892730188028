import * as yup from "yup";

const phoneRegExp = /^(([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
let namevalidate = /^[^\d]*$/

export const contactUsSchema = yup.object().shape({
  name: yup.string().required("Please enter your name").matches(namevalidate, 'Name should be characters only.').min(3, 'Name must be atleast 3 characters').max(25,'Name must not be greater than 25 characters'),
  email: yup.string().email('Email must be a valid email').required("Please enter your email"),
  country_code: yup.string().when(["phone"], {
    is: (phone, country_code) => phone || country_code,
    then: () => yup.string().required("Please select phone code.")
  }),
 
  phone: yup.string().when(["country_code"], {
    is: (country_code, phone) => { return country_code || phone } ,
    then: () => yup.string().required("Please select phone number.").min(9, 'Phone must be at least 9 characters').max(10, 'Phone must not exceed 10 characters')
  }),
 
  text: yup.string().required("Please write about your concern."),
  // subject: yup.string().required('Please select the subject.'),
  
}, [
  [], [], ['phone', 'country_code'], ['phone', 'country_code'], [], []
]);


export const webinarSchema =  yup.object().shape({
  name: yup.string().required("Please enter your name").matches(namevalidate, 'Name should be characters only.').min(3, 'Name must be atleast 3 characters').max(25,'Name must not be greater than 25 characters'),
  email: yup.string().email('Email must be a valid email').required("Please enter your email"),
  phone_code: yup.string().when(["phone"], {
    is: (phone, country_code) => phone || country_code,
    then: () => yup.string().required("Please select phone code.")
  }),
  phone_number: yup.string().when(["country_code"], {
    is: (country_code, phone) => { return country_code || phone } ,
    then: () => yup.string().required("Please select phone number.").min(6, 'Phone must be at least 6 characters').max(16, 'Phone must not exceed 16 characters')
  }),
  
}, [
  [], [], ['phone', 'country_code'], ['phone', 'country_code'], [], []
]);