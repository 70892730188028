import { notesConstants } from "../Constants/NoteConstants"

const initialState = { 
    loading: false,
    notes: [],
    saveLoading:false,
    noteLoadMoreData:[],
    pauseTime:'00:00:00',
    saveNoteButton:false,
    noteSavedLoadMoreData:[],
    savedNotes:[]
}

export const notesReducers   = ( state=initialState, action ) => {
    switch(action.type) {
        case notesConstants.GET_NOTES_PENDING:
            return {
                ...state,
                loading: true,
                notes:[],
               
            };
            break;

        case notesConstants.GET_NOTES_SUCCESS:
            let updatedMoreData = [...state.noteLoadMoreData];
            // Filerting data to avoid duplicacy entry...
            const newLessons = action.payload.data.filter(lesson => {
                return !updatedMoreData.some(existingSong => existingSong.id === lesson.id);
            });
            updatedMoreData = updatedMoreData.concat(newLessons);

            return {
                ...state,
                loading: false,
                notes: action.payload,
                noteLoadMoreData:updatedMoreData

            };
            break;

        case notesConstants.GET_NOTES_FAILURE:
            return {
                ...state,
                loading: false,
            };
            break;

        case notesConstants.GET_DELETE_PENDING: {
            return {
                ...state,
                loading: true
            }
        }

        case notesConstants.GET_DELETE_SUCCESS:
            const updatedNotes = state.noteLoadMoreData.filter(
                (note) => note.id !== action.payload
            );
            const updatedSavedNotes = state.noteSavedLoadMoreData.filter(
                (note) => note.id !== action.payload
            );

            return {
                ...state,
                loading: false,
                noteLoadMoreData: updatedNotes,
                noteSavedLoadMoreData:updatedSavedNotes,
                notes: action.payload,
                savedNotes:action.payload
            };
            
        case notesConstants.GET_DELETE_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }

        case notesConstants.EDIT_NOTES_PENDING:
            return {
                ...state,
                saveLoading:true,
                saveNoteButton:true,
                loading: true,
                noteLoadMoreData: [],
            };
            break;

        case notesConstants.EDIT_NOTES_SUCCESS:
            return {
                ...state,
                notes: action.payload,
                saveLoading:false,
                saveNoteButton:false
            };
            break;

        case notesConstants.EDIT_NOTES_FAILURE:
            return {
                ...state,
                error: action.error,
                saveLoading:false,
                saveNoteButton:false
            };
            break;

        case 'NOTE_DATA':
            return { 
                ...state,
                noteLoadMoreData: [],
                noteSavedLoadMoreData:[]
                
            };
            break;

        case 'PAUSE_TIME':
            return { 
                ...state,
                pauseTime: action.payload,
            };
            break;


        case notesConstants.GET_SAVED_NOTES_PENDING:
            return {
                ...state,
                loading: true,
                savedNotes:[],
               
            };
            break;

        case notesConstants.GET_SAVED_NOTES_SUCCESS:
            let updatedSavedMoreData = [...state.noteSavedLoadMoreData];
            // Filerting data to avoid duplicacy entry...
            const newSavedData = action.payload.data.filter(lesson => {
                return !updatedSavedMoreData.some(existingSong => existingSong.id === lesson.id);
            });
            updatedSavedMoreData = updatedSavedMoreData.concat(newSavedData);

            return {
                ...state,
                loading: false,
                savedNotes: action.payload,
                noteSavedLoadMoreData:updatedSavedMoreData

            };
            break;

        case notesConstants.GET_SAVED_NOTES_FAILURE:
            return {
                ...state,
                loading: false,
            };
            break;

        default:
            return state;
    }
}