import { noteService } from "../Adapters/note.services"
import { notesConstants } from "../Constants/NoteConstants";
import { toast } from "react-toastify";
import CustomErrorToast from "../components/CustomToaster/CustomErrorToast";
import CustomSuccessToast from "../components/CustomToaster/CustomSuccessToaster";

export const notes = {
    getNotes,
    deleteNotes,
    editNotes,
    getSavedNotes
}

async function handleRequest(endpoint, params, successType, failureType, headers = null) {
    try {
        const response = headers
            ? await noteService.sendTokenInHeader(endpoint, params, headers)
            : await noteService.sendRequest(endpoint, params);
            
        if (response?.status === 200 && response?.data?.code === "SUCCESS") {
            if (
                response?.data?.message === "Login successful!" ||
                response?.data?.message === "Logout successful!"
            ) {
                console.log('');
            } else {
                toast(<CustomSuccessToast message={response?.data?.message} />)
            }
            return { type: successType, payload: response?.data };
        } else if (response?.status === 201 && response?.data?.code === "SUCCESS") {
            toast(<CustomSuccessToast message={response?.data?.message} />)
            return { type: successType, payload: response?.data };
        } else if (response?.status === 404 && response?.data?.code === "ERROR") {
            if (response?.data?.errors) {
                for (const key in response?.data?.errors) {
                    if (response?.data?.errors.hasOwnProperty(key)) {
                        response?.data?.errors[key].forEach((errorMessage) => {
                            toast(<CustomErrorToast message={errorMessage} />)
                        });
                    }
                }
            } else {
                if(response?.data?.message == "User not found or invalid token."){
                    console.log('error');
                } else {
                    toast(<CustomErrorToast message={response?.data?.message} />)
                }
               
            }
            
            return { type: failureType, payload: response?.data };
        } else if (response?.status === 400 && response?.data?.code === "ERROR") {
            if (response?.data?.errors) {
                for (const key in response?.data?.errors) {
                    if (response?.data?.errors.hasOwnProperty(key)) {
                        response?.data?.errors[key].forEach((errorMessage) => {
                            toast(<CustomErrorToast message={errorMessage} />)
                        });
                    }
                }
            } else {
                toast(<CustomErrorToast message={response?.data?.message} />)
            }
            return { type: failureType, payload: response?.data };
        } else if (response?.status === 401 && response?.data?.code === "ERROR") {
            if (response?.data?.errors) {
                for (const key in response?.data?.errors) {
                    if (response?.data?.errors.hasOwnProperty(key)) {
                        response?.data?.errors[key].forEach((errorMessage) => {
                            toast(<CustomErrorToast message={errorMessage} />)
                        });
                    }
                }
            } else {
                toast(<CustomErrorToast message={response?.data?.message} />)
            }
            return { type: failureType, payload: response?.data };
        } else if (response?.status === 500 ) {
            toast(<CustomErrorToast message={'Internal server error'} />)
            return { type: failureType, payload: response?.data };
        } else {
            toast(<CustomErrorToast message={'Invalid api request'} />)
            return { type: failureType, payload: response?.data };
        }
    } catch (error) {
        toast(<CustomErrorToast message={'Some error occured'} />)
    }
}


async function editNotes(params, headers) {
    return handleRequest(`/api/edit-notes`, params,  notesConstants.EDIT_NOTES_SUCCESS, notesConstants.EDIT_NOTES_FAILURE, headers);
}

function getNotes(id, params, headers) {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/notes/${id}`);
    for (let key in params) {
        url.searchParams.append(key, params[key]);
    }

    return (dispatch) => {
        dispatch(request());
        noteService
        .getNotes(url.href, headers)
        .then((response) => {
            dispatch(success(response?.data?.data));
        })
        .catch((error) => {
            dispatch(errorNotes(error));
        });
    };
  
    function errorNotes(error) {
        return { type: notesConstants.GET_NOTES_FAILURE, error: error };
    }
  
    function request() {
        return { type: notesConstants.GET_NOTES_PENDING };
    }
  
    function success(data) {
        return { type: notesConstants.GET_NOTES_SUCCESS, payload: data };
    }
}

function deleteNotes(id, headers) {
    return (dispatch) => {
        dispatch(request());
        noteService
        .getNotes(`/api/delete-notes/${id}`, headers)
        .then((response) => {
            dispatch(success(id));
            toast(<CustomSuccessToast message={response?.data?.message} />);
        })
        .catch((error) => {
            console.log(error)
            dispatch(errorDeleteNotes(error));
            toast(<CustomErrorToast message={error?.data?.message} />);
        });
    };
  
    function errorDeleteNotes(error) {
        return { type: notesConstants.GET_DELETE_FAILURE, error: error };
    }
  
    function request() {
        return { type: notesConstants.GET_DELETE_PENDING };
    }
  
    function success(data) {
        return { type: notesConstants.GET_DELETE_SUCCESS, payload: data };
    }
}

function getSavedNotes( params, headers) {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/notes`);
    for (let key in params) {
        url.searchParams.append(key, params[key]);
    }

    return (dispatch) => {
        dispatch(request());
        noteService
        .getNotes(url.href, headers)
        .then((response) => {
            dispatch(success(response?.data?.data));
        })
        .catch((error) => {
            dispatch(errorSavedNotes(error));
        });
    };
  
    function errorSavedNotes(error) {
        return { type: notesConstants.GET_SAVED_NOTES_FAILURE, error: error };
    }
  
    function request() {
        return { type: notesConstants.GET_SAVED_NOTES_PENDING };
    }
  
    function success(data) {
        return { type: notesConstants.GET_SAVED_NOTES_SUCCESS, payload: data };
    }
}
  