export const QaConstants = {

    GET_DISCUSSIONS_PENDING: "GET_DISCUSSIONS_PENDING",
    GET_DISCUSSIONS_SUCCESS: "GET_DISCUSSIONS_SUCCESS",
    GET_DISCUSSIONS_FAILURE: "GET_DISCUSSIONS_FAILURE",


    POST_DISCUSSION_PENDING: "POST_DISCUSSION_PENDING",
    POST_DISCUSSION_SUCCESS: "POST_DISCUSSION_SUCCESS",
    POST_DISCUSSION_FAILURE: "POST_DISCUSSION_FAILURE",


    GET_DISCUSSIONS_REPLIES_PENDING: "GET_DISCUSSIONS_REPLIES_PENDING",
    GET_DISCUSSIONS_REPLIES_SUCCESS: "GET_DISCUSSIONS_REPLIES_SUCCESS",
    GET_DISCUSSIONS_REPLIES_FAILURE: "GET_DISCUSSIONS_REPLIES_FAILURE",

    DELETE_COMMENT_PENDING: "DELETE_COMMENT_PENDING",
    DELETE_COMMENT_SUCCESS: "DELETE_COMMENT_SUCCESS",
    DELETE_COMMENT_FAILURE: "DELETE_COMMENT_FAILURE",


}
