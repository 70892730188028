export const faqConstants = {
    CONTACT_REQUEST: "CONTACT_REQUEST",
    CONTACT_SUCCESS: "CONTACT_SUCCESS",
    CONTACT_FAILURE: "CONTACT_FAILURE",

    FAQ_SUCCESS: "FAQ_SUCCESS",
    FAQ_FAILURE: "FAQ_FAILURE",
    FAQ_PENDING: "FAQ_PENDING",

    FAQ_CATEGORY_PENDING: " FAQ_CATEGORY_PENDING",
    FAQ_CATEGORY_SUCCESS: "FAQ_CATEGORY_SUCCESS",
    FAQ_CATEGORY_FAILURE: "FAQ_CATEGORY_FAILURE",
    
    COUNTRY_CODE_PENDING: " COUNTRY_CODE_PENDING",
    COUNTRY_CODE_SUCCESS: "COUNTRY_CODE_SUCCESS",
    COUNTRY_CODE_FAILURE: "COUNTRY_CODE_FAILURE"
}
