import { post, get } from "./xhr";

export const bandsService = {
    getAboutBands,
    getEventsData,
    getZonesData,
    getBandsFaq,
    getYearsImageData,
    getUrlsData,
    getfollowers,
    
};

function getAboutBands(url, params) {
    return get(url, params);
}


function getEventsData(url, params){
    return get(url, params);
}

function getZonesData(url, params){
    return get(url, params);
}

function getBandsFaq(url, params){
    return get(url, params);
}

function getYearsImageData(url, params){
    return get(url, params);
}

function getUrlsData(url, params){
    return get(url, params);
}

function getfollowers(url, params){
    return get(url, params);
}



